import React from 'react';
import PropTypes from 'prop-types';
import Background from 'components/common/Background';
import { Card, Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from 'assets/img/atlass/logo_atlass.png';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from 'components/common/Toast';
import 'react-toastify/dist/ReactToastify.css';

const AuthSplitLayout = ({ children, bgProps }) => {
  return (
    <Container fluid>
      <Row className="min-vh-100 bg-100">
        <Col xs={6} xl={7} xxl={8} className="d-none d-lg-block position-relative">
          <div style={{ position: 'aboslute', width: '100%', height: '50vh', marginTop: '300px', zIndex: '111' }}>
            YOUR PERSONAL TRANSPORTATION SOFTWARE SOLUTION
            </div>
          {bgProps && <Background {...bgProps} />}
        </Col>
        <Col sm={10} md={6} xl={5} xxl={4} className="px-sm-0 align-self-center mx-auto py-5 order-first">
          <Row className="g-0 justify-content-center">
            <Col lg={9} xl={8} xxl="8">
              <div className="p-4">{children}</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer autoClose={5000}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Container>

  );
};

AuthSplitLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bgProps: PropTypes.shape(Background.propTypes).isRequired
};

export default AuthSplitLayout;
