import React, { useState, useEffect } from 'react';
import {
  useHistory,
  useParams
} from "react-router-dom";
import APICommunicator from 'api/APICommunicator'
import ProfileBanner from './Banner';
import IconButton from 'components/common/IconButton';
import EditUserModal from '../../modals/EditUserModal';
import { useSearch } from 'context/SearchContext';

const Profile = () => {

  const API = new APICommunicator('Users');
  const history = useHistory()
  const { addRecentItem } = useSearch()
  const { USER_ID } = useParams();
  const [userProfile, setUserProfile] = useState({ placeholder: true })
  const [modalShow, setModalShow] = useState(false);
  const [editUserFormData, setEditUserFormData] = useState({});


  const GetUser = async () => {
    await API.GET(`/${USER_ID}`)
      .then(Response => {
        if (!Response || Response.status != 'success') return console.log(Response)
        var USER = Response.content
        setUserProfile(USER);
        delete USER.id;
        delete USER.activation_code;
        delete USER.username;
        delete USER.role;
        delete USER.role_label;
        setEditUserFormData(USER)
        addRecentItem({
          ...USER,
          category_type: 'user',
          url: `/Users/${USER_ID}`,
          id: USER.uuid
        })
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    let mounted = true;
    GetUser();
    return () => mounted = false;
  }, [])

  return (
    <>
      <IconButton
        onClick={() => { history.goBack() }}
        variant="falcon-default"
        size="sm"
        icon="arrow-left"
        className=" mt-1"
        transform="shrink-3">
        <span className="d-none d-sm-inline-block ms-1">Back</span>
      </IconButton>
      <IconButton
        onClick={() => { setModalShow(true) }}
        variant="falcon-primary"
        size="sm"
        icon="edit"
        className="float-end mt-1"
        transform="shrink-3">
        <span className="d-none d-sm-inline-block ms-1">Edit</span>
      </IconButton>
      <div className="mt-6">
        <ProfileBanner user={userProfile} />
      </div>

      {/*  ADD USER MODAL  */}
      <EditUserModal
        show={modalShow}
        USER_ID={USER_ID}
        handleClose={() => { setModalShow(false) }}
        successCallback={user => setUserProfile(user)}
        inheritedFormData={editUserFormData}
      />

    </>
  );
};

export default Profile;
