
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import APICommunicator from 'api/APICommunicator'
import Avatar from 'components/common/Avatar';
import GenericAvatar from 'assets/img/team/avatar.png';
import AsyncSelect from "react-select/async";

const SelectServiceUserRoleDropdown = ({ SERVICE_ID, defaultValue, onChange }) => {

    const [options, setOptions] = useState([])
    const API = new APICommunicator('Services');

    const formatResult = option => {

        option['value'] = option.id

        option['label'] = <div className="d-flex"><span className="">{option.name}</span></div>

        return option
    }

    const handleQuery = (inputValue, displayOptions) => {

        const SEARCH = (inputValue) ? `/?search=${encodeURIComponent(inputValue)}` : '';
        const RETURN = (displayOptions) ? displayOptions : setOptions;

        API.GET(`/${SERVICE_ID}/Roles/${SEARCH}`)
            .then(Result => {
                if (!Result || Result.status !== 'success') return RETURN([{ value: '', label: `Can't load results` }])
                if (!Result.content) return RETURN([])
                RETURN(Result.content.map(formatResult));
            })
    }

    useEffect(() => {
        handleQuery()
    }, [])

    return (
        <AsyncSelect
            closeMenuOnSelect={true}
            cacheOptions
            loadOptions={handleQuery}
            defaultOptions={options}
            placeholder='Select...'
            classNamePrefix="react-select"
            isClearable="true"
            defaultValue={defaultValue}
            onChange={onChange}
        />
    );
};

SelectServiceUserRoleDropdown.propTypes = {
    defaultValue: PropTypes.object,
    onChange: PropTypes.func,
};

export default SelectServiceUserRoleDropdown;