import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Col, Row, FloatingLabel, InputGroup, Alert } from 'react-bootstrap'
import APICommunicator from 'api/APICommunicator'
import SelectServiceUserRoleDropdown from './utils/SelectServiceUserRoleDropdown';

const EditServiceUserModal = ({ inheritedFormData, USER_ID, SERVICE_ID, show, handleClose, successCallback }) => {

    const API = new APICommunicator('Services');

    const [formData, setFormData] = useState(inheritedFormData)
    const [error, setError] = useState(false)
    const [usernameFieldProps, setUsernameFieldProps] = useState({})
    const [usernameUnavailable, setUsernameUnavailable] = useState(false)
    // const { firstname, lastname, username, email, phone, title, address_1, address_2, city, state, zip } = inheritedFormData;
    const handleSubmit = e => {
        e.preventDefault();
        API.PUT(`/${SERVICE_ID}/Users`, formData)
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleError(Response);
                if (typeof successCallback === 'function') successCallback(Response.content)
                handleClose()
            })
            .catch(handleError)
    }

    const CheckUsername = e => {
        e.preventDefault();
        const val = e.target.value
        if (!val) setUsernameFieldProps({})
        if (val) API.GET(`/${SERVICE_ID}/Users/CheckIfUserExist/${val}`)
            .then(Response => {
                if (Response.content.exist) {
                    setUsernameFieldProps({ 'isInvalid': true })
                    setUsernameUnavailable(true)
                }
                if (!Response.content.exist) {
                    setUsernameFieldProps({ 'isValid': true })
                    setUsernameUnavailable(false)
                }
            })
            .catch(handleError)
    }

    useEffect(() => {
        setFormData(inheritedFormData)
    }, [inheritedFormData]);

    const handleError = Response => setError(API.handleResponseErrors(Response))

    const handleFieldFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Edit service user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="First Name" className="mb-3">
                                <Form.Control type="text" placeholder="First Name" name="firstname" onChange={handleFieldFormChange} defaultValue={formData?.firstname} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="Last Name" className="mb-3">
                                <Form.Control type="text" placeholder="Last Name" name="lastname" onChange={handleFieldFormChange} defaultValue={formData?.lastname} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Form.Group as={Col} className="mb-1" controlId="username">
                        <Form.Label>Username</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Username"
                                aria-describedby="inputGroupPrepend"
                                required
                                name="username"
                                onChange={handleFieldFormChange}
                                onKeyUp={CheckUsername}
                                {...usernameFieldProps}
                                defaultValue={formData?.username}
                            />
                            <Form.Control.Feedback type="invalid">
                                Username already exists or is invalid. Please choose something else.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-3" controlId="exampleUserName">
                        <Form.Label>Role</Form.Label>
                        <SelectServiceUserRoleDropdown
                            SERVICE_ID={SERVICE_ID}
                            defaultValue={{
                                value: inheritedFormData?.role,
                                label: inheritedFormData?.role_label
                            }}
                            onChange={e => {
                                if (e && e.id) setFormData({ ...formData, ['role']: e.id });
                            }} />
                    </Form.Group>

                    <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                        <Form.Control type="email" placeholder="name@example.com" name="email" onChange={handleFieldFormChange} defaultValue={formData?.email} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Phone" className="mb-3">
                        <Form.Control type="text" placeholder="Phone" name="phone" onChange={handleFieldFormChange} defaultValue={formData?.phone} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Title" className="mb-3">
                        <Form.Control type="text" placeholder="title" name="title" onChange={handleFieldFormChange} defaultValue={formData?.title} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Address" className="mb-3">
                        <Form.Control type="text" placeholder="Address" name="address_1" onChange={handleFieldFormChange} defaultValue={formData?.address_1} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Address Line 2 " className="mb-3">
                        <Form.Control type="text" placeholder="Address Line 2" name="address_2" onChange={handleFieldFormChange} defaultValue={formData?.address_2} />
                    </FloatingLabel>

                    <Row>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="City" className="mb-3">
                                <Form.Control type="text" placeholder="City" name="city" onChange={handleFieldFormChange} defaultValue={formData?.city} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="State" className="mb-3">
                                <Form.Control type="text" placeholder="State" name="state" onChange={handleFieldFormChange} defaultValue={formData?.state} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="ZIP" className="mb-3">
                                <Form.Control type="text" placeholder="ZIP" name="zip" onChange={handleFieldFormChange} defaultValue={formData?.zip} />
                            </FloatingLabel>
                        </Col>
                    </Row>

                    {error &&
                        <Alert variant="warning" dismissible onClose={() => setError(false)} dismissible>
                            {(typeof error === 'object') ? error.map(e => <div key={e} style={{ 'whiteSpace': 'pre-line' }}>{e}</div>) : <div style={{ 'whiteSpace': 'pre-line' }}>{error}</div>}
                        </Alert >
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Form.Group>
                        <Button
                            variant="secondary"
                            onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            type="submit"
                            className="ms-4"
                            variant="primary"
                            disabled={!formData || !formData.firstname || !formData.lastname || !formData.username || !formData.email || usernameUnavailable}>Submit
                        </Button>
                    </Form.Group>
                </Modal.Footer>

            </Form>
        </Modal>
    )
}
export default EditServiceUserModal;