import React, { useState } from 'react';
import { Form, Modal, Button, Col, Row, FloatingLabel, InputGroup, Alert } from 'react-bootstrap'
import APICommunicator from 'api/APICommunicator'

const AddUserModal = ({ show, handleClose, successCallback }) => {

    const API = new APICommunicator('Users');

    const [formData, setFormData] = useState({})
    const [error, setError] = useState(false)
    const [usernameFieldProps, setUsernameFieldProps] = useState({})

    const handleSubmit = e => {
        e.preventDefault();
        API.POST('/', formData)
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleError(Response);
                if (typeof successCallback === 'function') successCallback(Response.content)
                handleClose()
            })
            .catch(handleError)
    }

    const CheckUsername = e => {
        e.preventDefault();
        const val = e.target.value
        if (!val) setUsernameFieldProps({})
        if (val) API.GET(`/CheckIfUserExist/${val}`)
            .then(Response => {
                if (Response.content.exist) setUsernameFieldProps({ 'isInvalid': true })
                if (!Response.content.exist) setUsernameFieldProps({ 'isValid': true })
            })
            .catch(handleError)
    }


    const handleError = Response => setError(API.handleResponseErrors(Response))

    const handleFieldFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Add new user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="First Name" className="mb-3">
                                <Form.Control type="text" placeholder="First Name" name="firstname" onChange={handleFieldFormChange} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="Last Name" className="mb-3">
                                <Form.Control type="text" placeholder="Last Name" name="lastname" onChange={handleFieldFormChange} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Form.Group as={Col} className="mb-3" controlId="exampleUserName">
                        <Form.Label>Username</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Username"
                                aria-describedby="inputGroupPrepend"
                                required
                                name="username"
                                onChange={handleFieldFormChange}
                                onKeyUp={CheckUsername}
                                {...usernameFieldProps}
                            />
                            <Form.Control.Feedback type="invalid">
                                Username already exists or is invalid. Please choose something else.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                    <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                        <Form.Control type="email" placeholder="name@example.com" name="email" onChange={handleFieldFormChange} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Phone" className="mb-3">
                        <Form.Control type="text" placeholder="Phone" name="phone" onChange={handleFieldFormChange} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Title" className="mb-3">
                        <Form.Control type="text" placeholder="title" name="title" onChange={handleFieldFormChange} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Address" className="mb-3">
                        <Form.Control type="text" placeholder="Address" name="address_1" onChange={handleFieldFormChange} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Address Line 2 " className="mb-3">
                        <Form.Control type="text" placeholder="Address Line 2" name="address_2" onChange={handleFieldFormChange} />
                    </FloatingLabel>
                    <Row>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="City" className="mb-3">
                                <Form.Control type="text" placeholder="City" name="city" onChange={handleFieldFormChange} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="State" className="mb-3">
                                <Form.Control type="text" placeholder="State" name="state" onChange={handleFieldFormChange} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="ZIP" className="mb-3">
                                <Form.Control type="text" placeholder="ZIP" name="zip" onChange={handleFieldFormChange} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    {error && <Alert variant="warning" dismissible onClose={() => setError(false)} dismissible>
                        {(typeof error === 'object') ? error.map(e => <div key={e}>{e}</div>) : <div>{error}</div>}
                    </Alert >
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Form.Group>
                        <Button
                            variant="secondary"
                            onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            type="submit"
                            className="ms-4"
                            variant="primary"
                            disabled={!formData.firstname || !formData.lastname || !formData.username || !formData.email}>Submit
                        </Button>
                    </Form.Group>
                </Modal.Footer>

            </Form>
        </Modal>
    )
}
export default AddUserModal;