import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle, faCaretCircleRight, faPauseCircle, faTimesCircle, faQuestionCircle, faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';


const StatusBadgeLong = ({ status }) => {

    const STATUS = (status) ? status.toString() : false
    let classname, icon, text;

    switch (STATUS) {
        case '0':
            text = 'Pending Activation'
            classname = "badge rounded-pill badge-soft-primary fs--4";
            icon = <FontAwesomeIcon className="text-primary ms-2" icon={faDotCircle} />
            break;
        case '1':
            text = 'Active'
            classname = "badge rounded-pill badge-soft-success fs--4";
            icon = <FontAwesomeIcon className="text-success ms-2" icon={faCaretCircleRight} />
            break;
        case '2':
            text = 'Suspended'
            classname = "badge rounded-pill badge-soft-warning fs--4";
            icon = <FontAwesomeIcon className="text-warning ms-2" icon={faPauseCircle} />
            break;
        case '3':
            text = 'Terminated'
            classname = "badge rounded-pill badge-soft-danger fs--4";
            icon = <FontAwesomeIcon className="text-danger ms-2" icon={faTimesCircle} />
            break;
        case '9':
            text = 'Activating'
            classname = "badge rounded-pill badge-soft-primary fs--4";
            icon = <FontAwesomeIcon className="text-primary ms-2 fa-spin" icon={faSpinnerThird} />
            break;
        default:
            text = 'Unknown'
            classname = "badge rounded-pill badge-soft-default fs--4";
            icon = <FontAwesomeIcon className="text-default ms-2" icon={faTimesCircle} />
            break;
    }

    return (
        <>
            <div className={classname}>{text}{icon}</div>
        </>
    )

}

export default StatusBadgeLong