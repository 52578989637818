import React, { useState } from 'react';
import {
  useHistory,
  useParams
} from "react-router-dom";
import APICommunicator from 'api/APICommunicator'
import { Form, Card, Button, Col, Row, Placeholder } from 'react-bootstrap';
import GenericAvatar from 'assets/img/team/avatar.png';
import ProfileBanner from '../ProfileBanner';
import VerifiedBadge from 'components/common/VerifiedBadge';

const Banner = ({ user }) => {
  const API = new APICommunicator('Users');
  const { USER_ID } = useParams();
  const [file, setFile] = useState();
  const { profile_picture, firstname, lastname, title, role_label, username, id, placeholder } = user;

  const changeProfilePicture = e => {
    var formData = new FormData()
    const FILES = e.target.files
    // formData.append("Content-Type", "application/octet-stream")
    formData.append('file', FILES[0])
    // e.target.files.forEach(file => data.append(file.name, file))
    console.log(formData)

    API.UPLOAD(`/${USER_ID}/ChangeProfilePicture`, formData)
      .then(Response => {
        if (!Response || Response.status !== 'success') return console.log(Response);
        console.log(Response)
      })
      .catch(e => console.log(e))

  }
  return (
    <ProfileBanner>
      {/* <ProfileBanner.Header avatar={(profile_picture == '') ? GenericAvatar : GenericAvatar} /> */}
      <ProfileBanner.Header avatar={(profile_picture == '') ? GenericAvatar : profile_picture} />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {placeholder ? <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={4} /><br />
                <Placeholder xs={2} /><br />
                <Placeholder xs={3} />
              </Placeholder> : <div>{firstname} {lastname}</div>}
            </h4>
            <h5 className="fs-0 fw-normal">
              {role_label}
            </h5>
            <p className="text-500">{title}</p>
            <Form.Group controlId="formFile" size="sm" className="px-3">
              <Form.Label>Change profile picture</Form.Label>
              <Form.Control type="file" onChange={changeProfilePicture} />
            </Form.Group>
            {/*<Button variant="falcon-default" size="sm" className="px-3 ms-2">
              Message
            </Button> */}
            <div className="border-dashed-bottom my-4 d-lg-none" />
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

export default Banner;
