/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Users from 'components/pages/users/Users';
import Profile from 'components/pages/users/user/profile/Profile';
import Customers from 'components/pages/customers/Customers';
import Customer from 'components/pages/customers/Customer';
import Services from 'components/pages/services/Services';
import Service from 'components/pages/services/service/Service';


const MainRoutes = () => (
  <Switch>

    {/*Atlass*/}
    <Route path="/Users" exact component={Users} />
    <Route path="/Users/:USER_ID" component={Profile} />
    <Route path="/Customers" exact component={Customers} />
    <Route path="/Customers/:CUSTOMER_ID" component={Customer} />
    <Route path="/Services" exact component={Services} />
    <Route path="/Services/:SERVICE_ID" component={Service} />

    <Redirect to="/errors/404" />
  </Switch>
);

export default MainRoutes;
