import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Spinner } from 'react-bootstrap';
import PasswordStrengthBar from 'react-password-strength-bar';


const ActivateForm = ({ handleActivationSubmit, loading }) => {
    // State
    const [pass, setPass] = useState(false);
    const [passConfirm, setPassConfirm] = useState(false);
    const [passMatch, setPassMatch] = useState(false)
    const [passScore, setPassScore] = useState('')
    const [passScoreFeedback, setPassScoreFeedback] = useState('');
    const [formData, setFormData] = useState({});


    const handlePasswordSet = e => {
        e.preventDefault()
        if (e.target.name === 'password') setPass(e.target.value)
        if (e.target.name === 'password') setFormData({ ...formData, 'password': e.target.value })
        if (e.target.name === 'password_confirm') setPassConfirm(e.target.value)
        if (e.target.name === 'password_confirm') setFormData({ ...formData, 'password_confirm': e.target.value })
        setPassMatch((pass === passConfirm))
    }

    useEffect(() => {
        if (pass && passConfirm) setPassMatch((pass === passConfirm))
    }, [pass, passConfirm]);

    return (
        <Form id="activationForm" className="mt-4" onSubmit={e => {
            e.preventDefault()
            handleActivationSubmit(formData)
        }}>
            <Form.Group className="mb-3">
                <Form.Control
                    required
                    placeholder={'Password'}
                    name="password"
                    onKeyUp={handlePasswordSet}
                    onChange={handlePasswordSet}
                    type="password"
                    isInvalid={(pass && passScore !== false && passScore <= 1)}
                />
                <Form.Control.Feedback type="invalid">
                    {passScoreFeedback}
                </Form.Control.Feedback>
                {pass && <PasswordStrengthBar password={pass} onChangeScore={(score, feedback) => {
                    setPassScore(score);
                    if (feedback && feedback.suggestions) {
                        let suggestions = '';
                        feedback.suggestions.forEach(suggestion => {
                            suggestions += `${suggestion}\r\n`
                        })
                        setPassScoreFeedback(suggestions);
                    }
                }} />}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Control
                    required
                    placeholder={'Confirm Password'}
                    name="password_confirm"
                    onKeyUp={handlePasswordSet}
                    onChange={handlePasswordSet}
                    type="password"
                    isInvalid={(pass && passConfirm && !passMatch)}
                />
                <Form.Control.Feedback type="invalid">
                    Passwords don't match
                </Form.Control.Feedback>
            </Form.Group>

            {!loading ?
                <Form.Group className="mb-3">
                    <Button className="w-100" type="submit" disabled={(passMatch === false || (passScore < 1) || !passScore)}>
                        Activate my account!
                    </Button>
                </Form.Group>
                :
                <Button className="w-100" type="submit" disabled={true}>
                    <Spinner animation="border" />
                </Button>
            }
        </Form>
    );
};

ActivateForm.propTypes = {
    layout: PropTypes.string
};

ActivateForm.defaultProps = { layout: 'split' };

export default ActivateForm;
