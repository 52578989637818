import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import { ProgressBar } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';

const StorageUsageWidget = ({ data, className }) => {

    // USAGE EXAMPLE
    //   {
    //     name: 'Free',
    //     size: 576,
    //     color: '200',
    //      icon: <FontAwesome icon/>
    //   }

    const totalStorage = data
        .map(d => d.size)
        .reduce((total, currentValue) => total + currentValue, 0);

    return (
        <Card className={className}>
            <Card.Body as={Flex} alignItems="center">
                <div className="w-100">
                    <h6 className="mb-3 text-800">
                        Using
                        <strong className="mx-1 text-dark">
                            <CountUp end={(totalStorage / 1024).toFixed(2)} decimals={2} duration={1.75} separator=" " /> GB
                        </strong>
                    </h6>
                    <ProgressBar style={{ height: 13 }} className="rounded-3 mb-3">
                        {data.map(status => (
                            <ProgressBar
                                variant={status.color}
                                now={(status.size * 100) / totalStorage}
                                key={status.name}
                                className="border-end border-0 border-white"
                            />
                        ))}
                    </ProgressBar>
                    <Row className="fs--1 text-500">
                        {data.map(status => (
                            <Col xs="auto" as={Flex} alignItems="center" className="pe-2" key={status.name} >
                                {status.icon}
                                <span>{status.name}</span>
                                <span className="d-inline-block fw-semi-bold  ms-1 text-500">
                                    {(status.size > 1024) ? status.size / 1024 : status.size} {(status.size > 1024) ? 'GB' : 'MB'}
                                </span>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Card.Body>
        </Card>
    )
}

export default StorageUsageWidget