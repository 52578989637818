import React, { useState, useEffect } from 'react';
import APICommunicator from 'api/APICommunicator'
import IconButton from 'components/common/IconButton';
import { Col, Form, Row } from 'react-bootstrap';
import sampleusers from 'data/users';
import UserCard from './UserCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEmptySet } from '@fortawesome/pro-duotone-svg-icons';
import AddUserModal from './modals/AddUserModal';

const Users = () => {
    const API = new APICommunicator('Users');
    const [options] = useState([
        'Sales',
        'Tech support',
        'Administrator'
    ]);
    const [users, setUsers] = useState(sampleusers);
    const [searchField, setSearchField] = useState('');
    const [selectField, setSelectField] = useState('');
    const [modalShow, setModalShow] = useState(false);


    const ListUsers = () => {
        const QUERY = [{
            key: 'search',
            value: searchField
        }, {
            key: 'role_label',
            value: selectField
        }];

        const queryString = QUERY.map((q, i) => {
            let operator = (i == 0) ? "?" : "&"
            return `${operator}${q['key']}=${encodeURIComponent(q['value'])}`
        }).join('')

        setUsers(sampleusers) // sample users to show loading 

        API.GET(`/${queryString}`)
            .then(Response => {
                const USERS = Response.content
                if (Array.isArray(USERS)) {
                    setUsers(USERS)
                    // setUsers([])
                }
            })
            .catch(e => console.log(e))
    }


    useEffect(() => {
        let mounted = true;
        ListUsers()
        return () => mounted = false;
    }, [searchField, selectField])


    return (
        <>
            <div className="mt-2">
                <div>
                    <Row className="align-items-center">
                        <Col>
                            <IconButton onClick={() => setModalShow(true)} variant="falcon-default" size="sm" icon="plus" transform="shrink-3">
                                <span className="d-none d-sm-inline-block ms-1">New</span>
                            </IconButton>
                        </Col>
                        <Col>
                            <h5 className="mb-0 text-end">Users ({users.length}) </h5>
                        </Col>
                        <Col>
                            <Row className="g-2">
                                <Col>
                                    <Form.Control placeholder="Search..." size="sm" onKeyUp={(e) => { setSearchField(e.target.value) }} />
                                </Col>
                                <Col className="d-md-block d-none">
                                    <Form.Select size="sm" onChange={(e) => { setSelectField(e.target.value) }}>
                                        <option value="">All users</option>
                                        {options.map(option => (
                                            <option key={option}>{option}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4 px-1 pb-1 pt-0 fs--1">
                    <Row className="gx-0 gy-1 text-center">
                        {(users.length >= 1) ? users.map((user, index) =>
                            <Col key={user.id} xs={6} md={4} lg={3} xxl={2}>
                                <UserCard user={user} />
                            </Col>
                        )
                            :
                            <div className="text-400 my-4">
                                <FontAwesomeIcon icon={(searchField == '' && selectField == '') ? faEmptySet : faSearch} className="mb-4" size="6x" />
                                {(searchField == '' && selectField == '') ? <h5>No users</h5> : <h5>No results</h5>}
                                <p>You can add a new user by clicking the button below</p>
                                <IconButton variant="primary" icon="plus" size="md" transform="shrink-3">
                                    <span className="d-none d-sm-inline-block ms-1" onClick={() => setModalShow(true)}>Add new user</span>
                                </IconButton>
                            </div>
                        }
                    </Row>
                </div>
            </div>

            {/*  ADD USER MODAL  */}
            <AddUserModal show={modalShow} handleClose={() => { setModalShow(false) }} successCallback={() => { ListUsers() }} />

        </>
    );
};

export default Users;
