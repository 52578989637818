import React, { useState, useEffect } from 'react';
import {
    useHistory,
    useParams
} from "react-router-dom";
import { Card, Col, Row } from 'react-bootstrap';
import APICommunicator from 'api/APICommunicator'
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusBadgeLong from '../components/StatusBadgeLong';
import ServiceUsersList from './components/ServiceUsers/ServiceUsersList';
import StorageUsageWidget from '../components/StorageUsageWidget';
import { faDatabase, faUsers, faHdd } from '@fortawesome/pro-duotone-svg-icons';
import AuthenticationComponents from './components/AuthenticationComponents';
import AddServiceUserModal from './components/ServiceUsers/AddServiceUserModal';
import { useSearch } from 'context/SearchContext';



const Service = () => {
    const API = new APICommunicator('Services');
    const history = useHistory()
    const { addRecentItem } = useSearch()
    const { SERVICE_ID } = useParams();
    const [SERVICE, setService] = useState({ placeholder: true })
    const [storageUsage, setStorageUsage] = useState([{
        name: 'Storage',
        size: 0,
        color: '200',
        icon: <FontAwesomeIcon icon={faHdd} className={`text-primary fs--2 me-2`} />
    }])

    useEffect(() => {

        API.GET(`/${SERVICE_ID}`)
            .then(Response => {
                if (!Response || Response.status !== 'success') return console.log(Response)
                var SERVICE = Response.content
                setService(SERVICE)
                addRecentItem({
                    ...SERVICE,
                    category_type: 'service',
                    url: `/Services/${SERVICE_ID}`,
                    id: SERVICE.unique_id
                })
                document.title = `${SERVICE.label} | Services | Admin A.T.L.A.S.S.`
            })
            .catch(e => console.log(e))

    }, [])

    const extractMB = str => {
        if (!str || typeof str !== 'string') return 0;
        let RESULT = 1;
        let NUMBER = parseFloat(str.replace(" KB", "").replace(" MB", "").replace(" GB", "").replace(" TB", ""));
        if (str.includes(' KB')) RESULT = NUMBER / 1024;
        if (str.includes(' MB')) RESULT = NUMBER;
        if (str.includes(' GB')) RESULT = NUMBER * 1024;
        if (str.includes(' TB')) RESULT = NUMBER * 1024 * 1024;
        return parseFloat(RESULT.toFixed(2));
    }


    useEffect(() => {
        const Storage = [
            {
                name: 'Database',
                size: extractMB(SERVICE.database_size),
                color: 'warning',
                icon: <FontAwesomeIcon icon={faDatabase} className={`text-warning fs--2 me-2`} />
            },
            {
                name: 'Storage Bucket',
                size: extractMB(SERVICE.bucket_size),
                color: 'primary',
                icon: <FontAwesomeIcon icon={faHdd} className={`text-primary fs--2 me-2`} />
            }
        ];
        setStorageUsage(Storage)
    }, [SERVICE])

    return (
        <>
            <IconButton
                onClick={() => { history.goBack() }}
                variant="falcon-default"
                size="sm"
                icon="arrow-left"
                className=" mt-1"
                transform="shrink-3">
                <span className="d-none d-sm-inline-block ms-1">Back</span>
            </IconButton>

            {/* DETAILS */}
            <Card className="mt-3 mb-0">
                <Card.Header>
                    <Row>
                        <Col>
                            <h5 className="mb-0 fw-normal">
                                {SERVICE.label}
                            </h5>
                            <p className="text-muted mb-0 fs--1">Service ID: # {SERVICE.unique_id}</p>
                            <p className="text-muted mb-0 fs--1">Running since {new Date(SERVICE.date_added).toLocaleDateString("en-US")}</p>

                        </Col>
                        <Col xs="auto">
                            <h3 className="d-inline-block">{SERVICE && <StatusBadgeLong status={SERVICE.status} />}</h3>
                        </Col>
                    </Row>
                </Card.Header>
            </Card>

            {/* MODULES */}

            <StorageUsageWidget data={storageUsage} className="mt-3" />
            <ServiceUsersList SERVICE_ID={SERVICE_ID} SERVICE_LABEL={SERVICE.label} />
            <AuthenticationComponents SERVICE={SERVICE} SERVICE_LABEL={SERVICE.label} />
            {/* <MemberInfo data={SampleData} /> */}
        </>
    )
}

export default Service