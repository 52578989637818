
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import APICommunicator from 'api/APICommunicator'
import Avatar from 'components/common/Avatar';
import GenericAvatar from 'assets/img/team/avatar.png';
import AsyncSelect from "react-select/async";

const SelectAsyncCustomerDropdown = ({ defaultValue, onChange }) => {

    const [options, setOptions] = useState(defaultValue)
    const API = new APICommunicator('Customers');

    const formatResult = option => {

        option['value'] = option.id

        option['label'] = <div className="d-flex"><Avatar
            size="s"
            className="me-2 mt-1"
            src={(option.logo === '') ? '' : option.logo}
            name={(option.logo === '') ? '' : option.name}
            mediaClass="shadow-sm" /><span className="">{option.name}</span></div>

        return option
    }

    const handleQuery = (inputValue, displayOptions) => {

        const SEARCH = (inputValue) ? `/?search=${encodeURIComponent(inputValue)}` : '';
        const RETURN = (displayOptions) ? displayOptions : setOptions;

        API.GET(`/${SEARCH}`)
            .then(Result => {
                if (!Result || Result.status !== 'success') return RETURN([{ value: '', label: `Can't load results` }])
                if (!Result.content) return RETURN([])
                RETURN(Result.content.map(formatResult));
            })
    }

    useEffect(() => {
        handleQuery()
    }, [])

    return (
        <AsyncSelect
            closeMenuOnSelect={true}
            cacheOptions
            loadOptions={handleQuery}
            defaultOptions={options}
            placeholder='Select...'
            classNamePrefix="react-select"
            isClearable="true"
            value={defaultValue}
            onChange={onChange}
        />
    );
};

SelectAsyncCustomerDropdown.propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
};

export default SelectAsyncCustomerDropdown;