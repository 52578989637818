import React from 'react';
import { Card, Col, Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import GenericAvatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';


const UserCard = ({ user }) => {

    const { placeholder, profile_picture, firstname, lastname, title, role_label, uuid } = user;

    return (
        <Card className=" p-3 h-100 me-2 col xs-6 md-4 lg-3 xxl-2">
            <Link to={`/Users/${uuid}`}>
                <Avatar
                    size="4xl"
                    className="img-fluid mb-2"
                    src={(profile_picture === '') ? '' : profile_picture}
                    name={(profile_picture === '') ? `${firstname} ${lastname}` : ''}
                    mediaClass="img-thumbnail shadow-sm"
                />
            </Link>
            <h6 className="mb-1">
                {(placeholder) && <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={12} />
                </Placeholder>}
                {(!placeholder) && <Link to={`/Users/${uuid}`}>{firstname} {lastname}</Link>}
            </h6>
            <div className="fs--2 mb-1">
                <Link className="text-700" to="#!">
                    {(placeholder) && <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={6} />
                        <Placeholder xs={8} />
                    </Placeholder>}
                    {(!placeholder) && [
                        (title) && title,
                        <br />,
                        (role_label) && role_label
                    ]}
                </Link>
            </div>
        </Card>
    );
};

UserCard.propTypes = {
    user: PropTypes.shape({
        avatarSrc: PropTypes.string,
        name: PropTypes.string,
    })
};

export default UserCard;
