import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, Col, Row, FloatingLabel, Alert } from 'react-bootstrap'
import APICommunicator from 'api/APICommunicator'
import GenericAvatar from 'assets/img/team/avatar.png';

const EditUserModal = ({ show, handleClose, successCallback, inheritedFormData, USER_ID }) => {


    const API = new APICommunicator('Users');
    const [error, setError] = useState(false)
    const [formData, setFormData] = useState(inheritedFormData)

    const handleSubmit = e => {
        e.preventDefault()
        setError(false)
        API.PUT(`/${USER_ID}`, formData)
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleError(Response);
                if (typeof successCallback === 'function') successCallback(Response.content)
                handleClose()
            })
            .catch(handleError)
    }


    const handleError = Response => setError(API.handleResponseErrors(Response))

    const handleFieldFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })


    useEffect(() => {
        setFormData(inheritedFormData)
    }, [inheritedFormData]);

    return (

        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Edit user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="First Name" className="mb-3">
                                <Form.Control type="text" placeholder="First Name" name="firstname" onChange={handleFieldFormChange} defaultValue={(formData) ? formData.firstname : ''} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="Last Name" className="mb-3">
                                <Form.Control type="text" placeholder="Last Name" name="lastname" onChange={handleFieldFormChange} defaultValue={(formData) ? formData.lastname : ''} />
                            </FloatingLabel>
                        </Col>
                    </Row>

                    <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                        <Form.Control type="email" placeholder="name@example.com" name="email" onChange={handleFieldFormChange} defaultValue={(formData) ? formData.email : ''} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Phone" className="mb-3">
                        <Form.Control type="text" placeholder="Phone" name="phone" onChange={handleFieldFormChange} defaultValue={(formData) ? formData.phone : ''} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Title" className="mb-3">
                        <Form.Control type="text" placeholder="title" name="title" onChange={handleFieldFormChange} defaultValue={(formData) ? formData.title : ''} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Address" className="mb-3">
                        <Form.Control type="text" placeholder="Address" name="address_1" onChange={handleFieldFormChange} defaultValue={(formData) ? formData.address_1 : ''} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Address Line 2 " className="mb-3">
                        <Form.Control type="text" placeholder="Address Line 2" name="address_2" onChange={handleFieldFormChange} defaultValue={(formData) ? formData.address_2 : ''} />
                    </FloatingLabel>
                    <Row>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="City" className="mb-3">
                                <Form.Control type="text" placeholder="City" name="city" onChange={handleFieldFormChange} defaultValue={(formData) ? formData.city : ''} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="State" className="mb-3">
                                <Form.Control type="text" placeholder="State" name="state" onChange={handleFieldFormChange} defaultValue={(formData) ? formData.state : ''} />
                            </FloatingLabel>
                        </Col>
                        <Col>
                            <FloatingLabel controlId="floatingInput" label="ZIP" className="mb-3">
                                <Form.Control type="text" placeholder="ZIP" name="zip" onChange={handleFieldFormChange} defaultValue={(formData) ? formData.zip : ''} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    {error && <Alert variant="warning" dismissible onClose={() => setError(false)} dismissible>
                        {(typeof error === 'object') ? error.map(e => <div key={e}>{e}</div>) : <div>{error}</div>}
                    </Alert >
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Form.Group>
                        <Button
                            variant="secondary"
                            onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            type="submit"
                            className="ms-4"
                            variant="primary"
                            disabled={(formData && (!formData.firstname || !formData.lastname || !formData.email))}>
                            Submit</Button>
                    </Form.Group>
                </Modal.Footer>

            </Form>
        </Modal>
    )
}

export default EditUserModal