import bgr1 from '../../../assets/img/scenic/1.jpeg'
import bgr2 from '../../../assets/img/scenic/2.jpeg'
import bgr3 from '../../../assets/img/scenic/3.jpeg'
import bgr4 from '../../../assets/img/scenic/4.jpeg'
import bgr5 from '../../../assets/img/scenic/5.jpeg'
import bgr6 from '../../../assets/img/scenic/6.jpeg'
import bgr7 from '../../../assets/img/scenic/7.jpeg'
import bgr8 from '../../../assets/img/scenic/8.jpeg'
import bgr9 from '../../../assets/img/scenic/9.jpeg'
import bgr10 from '../../../assets/img/scenic/10.jpeg'
import bgr11 from '../../../assets/img/scenic/11.jpeg'
import bgr12 from '../../../assets/img/scenic/12.jpeg'

const bgrImages = [
    bgr1,
    bgr2,
    bgr3,
    bgr4,
    bgr5,
    bgr6,
    bgr7,
    bgr8,
    bgr9,
    bgr10,
    bgr11,
    bgr12,
];

export default bgrImages;