import React, { useState, useEffect } from 'react';
import PasswordResetForm from 'components/authentication/ResetPasswordForm';
import {
  Link,
  useHistory,
  useParams
} from "react-router-dom";
import bgrImages from './randomBgr';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import { Button, Alert, Collapse, Spinner } from 'react-bootstrap';
import Lottie from 'react-lottie';
import ForgotPasswordAnimation from 'assets/img/animated-icons/94132-forgot-password'
import invalidAnimation from 'assets/img/animated-icons/54117-invalid'
import successAnimation from 'assets/img/animated-icons/88860-success-animation'
import { useAuth } from 'context/AuthContext'
import { toast } from 'react-toastify'
import APICommunicator from 'api/APICommunicator'

const API = new APICommunicator('');

const PasswordReset = () => {

  const { AUTH_KEY } = useParams();
  const randomBgr = bgrImages[Math.floor(Math.random() * bgrImages.length)];
  const { reset_password, check_password_reset_code } = useAuth();
  const [resetSuccess, setResetSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [KEY, setKey] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [resetLoading, setResetLoading] = useState(false)

  const errorAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: invalidAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const forgotPasswordAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: ForgotPasswordAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const successAnimationOptions = {
    loop: false,
    autoplay: false,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // RESET SUBMIT HANDLERS

  const handleResetPasswordError = Response => {
    console.log(Response);
    toast.error(API.handleResponseErrors(Response), {
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false
    })
  }

  const handleResetPasswordResponse = Response => {
    setResetLoading(false)
    if (!Response || Response.status !== 'success') return handleResetPasswordError(Response)
    setSuccessMessage(Response.message)
    setResetSuccess(true)
  }

  const handleResetSubmit = formData => {
    setResetLoading(true)
    reset_password(AUTH_KEY, formData.password, formData.password_confirm)
      .then(handleResetPasswordResponse)
      .catch(handleResetPasswordError)
  }

  // KEY VALIDATION HANDLERS

  const handleKeyValidationError = Response => setError(true)

  const handleKeyValidationResponse = Response => {
    setLoading(false)
    if (!Response || Response.status !== 'success') return handleKeyValidationError(Response)
  }

  const handleKeyValidation = KEY => {
    setLoading(true)
    check_password_reset_code(KEY)
      .then(handleKeyValidationResponse)
      .catch(handleKeyValidationError)
  }

  useEffect(() => {
    handleKeyValidation(AUTH_KEY)
  }, [])

  return (
    <AuthSplitLayout bgProps={{ image: randomBgr }}>
      {loading ? <div className="text-center"><Spinner animation="border" variant="primary" /></div> :

        error ?
          <div>
            <div><Lottie options={errorAnimationOptions} /></div>
            <Alert variant="danger">We're sorry, this reset link is either invalid or expired.</Alert>
            <Link to="/login"> &larr; Already reset your password? Try logging in </Link>
          </div> :
          <>
            <Collapse in={!resetSuccess}>

              <div className="text-center">
                <div><Lottie options={forgotPasswordAnimationOptions} ariaRole="div" /></div>
                <h4 className="mb-0">Forgot your password?</h4>
                <small>Fill the form below to set a new password</small>
                <PasswordResetForm handleSubmit={handleResetSubmit} loading={resetLoading} />
              </div>

            </Collapse>
            <Collapse in={resetSuccess}>
              <div className="text-center" id="activationSuccess">
                <div><Lottie options={successAnimationOptions} isStopped={!resetSuccess} ariaRole="div" /></div>
                <div>
                  <h3 className="mb-0 fw-bold">Password successfully reset!</h3>
                  <p>You may now continue to login using your new pasword</p>
                  <Link to="/login"><Button variant="primary">Login</Button></Link>
                </div>
              </div>
            </Collapse>
          </>
      }
    </AuthSplitLayout>
  );
};

export default PasswordReset;
