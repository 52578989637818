import React from "react"
import { Route, Redirect } from "react-router-dom"
import { isAuthenticated, getUserData, } from "../context/AuthContext"


export default function PrivateRoute({ component: Component, ...rest }) {
    // console.log(isAuthenticated())
    const UserData = isAuthenticated() ? JSON.parse(getUserData()) : false

    // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') console.log(UserData)

    return (
        <Route
            {...rest}
            render={props => {
                // return isAuthenticated() ? <SocketContext.Provider value={socket}><Component {...props} /></SocketContext.Provider> : <Redirect to="/login" />
                return isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
            }}
        ></Route>
    )
}