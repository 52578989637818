export const dashboardRoutes = {
  label: 'Main',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: ['fad', 'coffee'],
      exact: true,
      to: '/'
    },
    {
      name: 'Services',
      active: true,
      icon: ['fad', 'server'],
      exact: false,
      to: '/Services'
    },
    {
      name: 'Customers',
      active: true,
      icon: ['fad', 'briefcase'],
      exact: false,
      to: '/Customers'
    },
    {
      name: 'Users',
      active: true,
      icon: ['fad', 'users'],
      exact: false,
      to: '/Users'
    }
  ]
};

export default [
  dashboardRoutes
];