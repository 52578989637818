import React, { useContext, useState, useEffect } from 'react'
import {  useHistory } from 'react-router-dom';
import Cookies from 'js-cookie'
import { BASE_URL } from '../api/APICommunicator'
import {autoCompleteInitialItem, defaultRecentItems} from 'data/autocomplete/autocomplete';
import APICommunicator from 'api/APICommunicator'

const COOKIE_VARIABLES = {
    RECENT_ITEMS: 'SEARCH_DATA_RECENT_ITEMS',
    SEARCH_RESULTS: 'SEARCH_DATA_SEARCH_RESULTS',
}

export const SearchContext = React.createContext();

export function useSearch() {
    return useContext(SearchContext)
}

export function SearchProvider({ children }) {

    const [loading, setLoading] = useState(false);
    const [recentItems, setRecentItems] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    // ===================================================
    // ===================================================
    // ===================================================
    // RECENT ITEMS

    const addRecentItem = item => {
        
        if (!item) return;

        const MAX_RECENT_ITEMS = 5
        var RECENT_ITEMS = getRecentItems()
        
        // REMOVE THIS ITEM IF IT ALREADY EXISTS
        if (typeof RECENT_ITEMS === 'object' && RECENT_ITEMS.length >= 1) {
            let filtered_items  = RECENT_ITEMS.filter(i => {
                return  ((!i || !item) || i?.category_type === item?.category_type && i?.id === item?.id) ? false : item
            })
            RECENT_ITEMS = filtered_items
        }

        // SET MAX RECENT ITEMS AND REMOVE OLDER ITEMS
        if (typeof RECENT_ITEMS === 'object' && RECENT_ITEMS.length >= MAX_RECENT_ITEMS) {
            for (let i = RECENT_ITEMS.length; i == MAX_RECENT_ITEMS; i--){
                RECENT_ITEMS.pop()
            }
        }

        // ADD ITEM TO TOP OF ARRAY
        RECENT_ITEMS.unshift(item)

        // SET TO RECENT
        setRecentItems(RECENT_ITEMS)

        // SAVE WITH COOKIES 
        Cookies.set(COOKIE_VARIABLES.RECENT_ITEMS, JSON.stringify(RECENT_ITEMS), {
            sameSite: 'strict',
            expires: 365,
        })

    }

    const getRecentItems = () => {
        const RECENT_ITEMS = Cookies.get(COOKIE_VARIABLES.RECENT_ITEMS)
        setRecentItems(RECENT_ITEMS ? JSON.parse(RECENT_ITEMS) : [])
        return RECENT_ITEMS ? JSON.parse(RECENT_ITEMS) : []
    }


    // ===================================================
    // ===================================================
    // ===================================================
    // SEARCH ITEMS

    const getLastSearchResult = () => {
        const LAST_SEARCH_RESULTS = Cookies.get(COOKIE_VARIABLES.SEARCH_RESULTS)
        return LAST_SEARCH_RESULTS ? JSON.parse(LAST_SEARCH_RESULTS) : []
    }

    const addDataToSearchResults = ({data, name, weight}) => {
        // no data -- return
        if (!data || typeof data !== 'object' || data.length <= 0) return

        // get last search results 
        var LAST_SEARCH_RESULTS = getLastSearchResult() || []
        
        data.forEach(item => {
            let id = 0
            let url = '/'
            switch (name) {
                case 'service':
                    id = item.unique_id
                    url = `/Services/${id}`
                    break;
                case 'customer':
                    id = item.uuid
                    url = `/Customers/${id}`
                    break;
                case 'user':
                    id = item.uuid
                    url = `/Users/${id}`
                    break;
                default:
                    break;
            }

            const formattedItem = {
                ...item,
                id: id,
                url: url,
                category_type: name,
                weight: weight
            }


            // REMOVE EXISTING ITEMS
            if (typeof LAST_SEARCH_RESULTS === 'object' && LAST_SEARCH_RESULTS.length >= 1) {
                
                let filtered_items  = LAST_SEARCH_RESULTS.filter(i => {
                    return  ((!i || !formattedItem) || i?.category_type === formattedItem?.category_type && i?.id === formattedItem?.id) ? false : formattedItem
                })
                LAST_SEARCH_RESULTS = filtered_items
            }


            // ADD ITEM TO TOP OF ARRAY
            LAST_SEARCH_RESULTS.unshift(formattedItem)
        })

        // SET TO STATE
        setSearchResults(LAST_SEARCH_RESULTS)

        LAST_SEARCH_RESULTS.sort((a, b) => a.weight - b.weight)


        // SAVE WITH COOKIES 
        Cookies.set(COOKIE_VARIABLES.SEARCH_RESULTS, JSON.stringify(LAST_SEARCH_RESULTS), {
            sameSite: 'strict',
            expires: 1,
        })
    }

    const handleSearchError = Response => console.log(new APICommunicator('').handleResponseErrors(Response))

    const handleSearchResponse = ({Response, name, weight}) => {
        if (!Response || Response.status !== 'success') return handleSearchError(Response)
        addDataToSearchResults({data: Response.content, name: name, weight: weight})
    }
    
    const searchForItems = searchValue => {
        // setLoading(true)
        const SearchCategories = [
            {
                ENDPOINT: 'Services',
                name: 'service',
                weight: 0
            },
            {
                ENDPOINT: 'Users',
                name: 'user',
                weight: 1
            },
            {
                ENDPOINT: 'Customers',
                name: 'customer',
                weight: 2
            }
        ];
        
        const PROMISE_ARRAY = [];
        
        SearchCategories.forEach(Category =>  PROMISE_ARRAY.push(new Promise((resolve, reject) => {
            const API = new APICommunicator(Category.ENDPOINT)
            API.GET(`/`)
            // API.GET(`/?search=${searchValue}`)
                .then(Response => { handleSearchResponse({...Category, Response: Response}); resolve() })
                .catch(e => { handleSearchError(e); resolve()})

        })))

        return Promise.all(PROMISE_ARRAY)
        // .then(setLoading(false))
    }



    // ===================================================
    // ===================================================
    // ===================================================

    useEffect(() => { 
        getRecentItems()
    },[])


    const value = {
        searchResults,
        recentItems,
        addRecentItem,
        searchForItems,
        loading
    }
 
    return (
        <SearchContext.Provider value={value}>
            {children}
        </SearchContext.Provider>
    )
}