import person2 from 'assets/img/team/2.jpg';
import person3 from 'assets/img/team/3.jpg';
import person4 from 'assets/img/team/4.jpg';
import person5 from 'assets/img/team/5.jpg';
import person6 from 'assets/img/team/6.jpg';
import person9 from 'assets/img/team/9.jpg';
import person13 from 'assets/img/team/13.jpg';
import person14 from 'assets/img/team/14.jpg';

export const customersData = [
//   {
//     name: 'Eagle Express, INC',
//     avatar: {
//       name: 'Eagle Express, INC'
//     },
//     email: 'dispatch@eagleexpress05.com',
//     phone: '(855) 993 2453',
//     address: '9979 Newton Ct, Westminster, CO 80031',
//     joined: '01/01/2019'
//   },
//   {
//     name: 'GES Trans',
//     avatar: {
//       name: 'G E S'
//     },
//     email: 'email@example.com',
//     phone: '(212) 228-8403',
//     address: '2289 5th Avenue, New York, New York, 10037',
//     joined: '01/01/2019'
//   },
//   {
//     name: 'KSK Transport',
//     avatar: {
//       name: 'K S K'
//     },
//     email: 'email@example.com',
//     phone: '(201) 200-1851',
//     address: '112 Bostwick Avenue, Jersey City, New Jersey, 0730',
//     joined: '01/01/2019'
//   },
//   {
//     name: 'BG Transportation',
//     avatar: {
//       name: 'B G T'
//     },
//     email: 'email@example.com',
//     phone: '(901) 324-3127',
//     address: '3448 Ile De France St #242, Fort Wainwright, Alaska, 99703',
//     joined: '05/04/2018'
//   },
//   {
//     name: 'County Line Express',
//     avatar: {
//       name: 'County Line Express'
//     },
//     email: 'email@example.com',
//     phone: '(828) 382-9631',
//     address: '659 Hannah Street, Charlotte, NC 28273',
//     joined: '17/03/2016'
//   }
];
