import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, FloatingLabel, Alert, FormGroup, Col, Row } from 'react-bootstrap'
import APICommunicator from 'api/APICommunicator'
import SelectAsyncCustomerDropdown from 'components/pages/customers/utils/SelectCustomerDropdown';
import SelectAsyncServersDropdown from 'components/pages/servers/component/SelectAsyncServerDropdown';

const AddServiceModal = ({ show, handleClose, successCallback }) => {

    const API = new APICommunicator('Services');

    const [formData, setFormData] = useState({})
    const [error, setError] = useState(false)

    const handleSubmit = e => {
        e.preventDefault();
        API.POST('/', formData)
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleError(Response);
                if (typeof successCallback === 'function') successCallback(Response.content)
                handleClose()
            })
            .catch(handleError)
    }


    const handleError = Response => setError(API.handleResponseErrors(Response))

    const handleFieldFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    return (
        <Modal show={show} onHide={handleClose}
            size="lg" centered
            aria-labelledby="contained-modal-title-vcenter">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Add new service</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <FloatingLabel controlId="floatingInput" label="Label" className="mb-3">
                        <Form.Control type="text" placeholder="Label" name="label" onChange={handleFieldFormChange} />
                    </FloatingLabel>

                    <Form.Label>Customer</Form.Label>
                    <SelectAsyncCustomerDropdown onChange={e => { if (e && e.id) setFormData({ ...formData, ['customer_id']: e.id }) }} />

                    <Form.Label>Database Server</Form.Label>
                    <SelectAsyncServersDropdown onChange={e => { if (e && e.id) setFormData({ ...formData, ['server_id']: e.id }) }} />

                    {error && <Alert variant="warning" className="mt-4" dismissible onClose={() => setError(false)} dismissible>
                        {(typeof error === 'object') ? error.map(e => <div key={e}>{e}</div>) : <div>{error}</div>}
                    </Alert >
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Form.Group>
                        <Button variant="secondary" onClick={handleClose}>  Close
                        </Button>
                        <Button type="submit" className="ms-4" variant="primary"
                            disabled={(!formData.label || !formData.server_id || !formData.customer_id)}>
                            Submit
                        </Button>
                    </Form.Group>
                </Modal.Footer>

            </Form>
        </Modal >
    )
}
export default AddServiceModal;