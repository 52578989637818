
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import APICommunicator from 'api/APICommunicator'
import Avatar from 'components/common/Avatar';
import GenericAvatar from 'assets/img/team/avatar.png';
import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase } from '@fortawesome/pro-duotone-svg-icons';

const SelectAsyncServersDropdown = ({ defaultValue, onChange, className }) => {

    const [options, setOptions] = useState([])
    const API = new APICommunicator('DBServers');

    const formatResult = option => {

        option['value'] = option.id

        option['label'] = <div className="d-flex"><FontAwesomeIcon icon={faDatabase} className="me-2 mt-1" /><span className="">{option.name}</span></div>

        return option
    }

    const handleQuery = (inputValue, displayOptions) => {

        const SEARCH = (inputValue) ? `/?search=${encodeURIComponent(inputValue)}` : '';
        const RETURN = (displayOptions) ? displayOptions : setOptions;

        API.GET(`/${SEARCH}`)
            .then(Result => {
                if (!Result || Result.status !== 'success') return RETURN([{ value: '', label: `Can't load results` }])
                if (!Result.content) return RETURN([])
                RETURN(Result.content.map(formatResult));
            })
    }

    useEffect(() => {
        handleQuery()
    }, [])

    return (
        <AsyncSelect
            closeMenuOnSelect={true}
            cacheOptions
            loadOptions={handleQuery}
            defaultOptions={options}
            placeholder='Select...'
            classNamePrefix="react-select"
            className={className}
            value={defaultValue}
            onChange={onChange}
        />
    );
};

SelectAsyncServersDropdown.propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
};

export default SelectAsyncServersDropdown;