import React, { useState, useEffect } from 'react';
import {
    useHistory,
    useParams
} from "react-router-dom";
import { Card, Col, Row } from 'react-bootstrap';
import APICommunicator from 'api/APICommunicator'
import IconButton from 'components/common/IconButton';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ServiceCard from 'components/pages/services/ServiceCard';
import { useSearch } from 'context/SearchContext';

const Customer = () => {
    const API = new APICommunicator('Customers');
    const history = useHistory()
    const { addRecentItem } = useSearch()
    const { CUSTOMER_ID } = useParams();
    const [CUSTOMER, setCustomerProfile] = useState({ placeholder: true })
    const [editCustomerFormData, setEditCustomerFormData] = useState({});


    useEffect(() => {
        let mounted = true;
        API.GET(`/${CUSTOMER_ID}`)
            .then(Response => {
                if (!Response || Response.status !== 'success') return console.log(Response)
                var CUSTOMER = Response.content
                setCustomerProfile(CUSTOMER)
                setEditCustomerFormData(CUSTOMER)
                addRecentItem({
                    ...CUSTOMER,
                    category_type: 'customer',
                    url: `/Customers/${CUSTOMER_ID}`,
                    id: CUSTOMER.uuid
                })
                document.title = `${CUSTOMER.name} | Admin A.T.L.A.S.S.`
            })
            .catch(e => console.log(e))
        return () => mounted = false;
    }, [])


    return (
        <>
            <IconButton
                onClick={() => { history.goBack() }}
                variant="falcon-default"
                size="sm"
                icon="arrow-left"
                className=" mt-1"
                transform="shrink-3">
                <span className="d-none d-sm-inline-block ms-1">Back</span>
            </IconButton>

            {/* CUSTOMER DETAILS */}
            <Card className="mt-3 mb-0">
                <Card.Header>
                    <Row>
                        <Col>
                            <h5 className="mb-2">
                                {CUSTOMER.name}<span className="mx-2">({CUSTOMER.id})</span>
                            </h5>
                        </Col>
                        <Col xs="auto">
                            <h6 className="text-uppercase text-600">
                                Customer
                                <FontAwesomeIcon icon="user" className="ms-2" />
                            </h6>
                        </Col>
                    </Row>
                </Card.Header>
            </Card>


            <div className="mt-3">
                <Card className="mb-3">
                    <Card.Header>
                        <Row className="align-items-center">
                            <Col>
                                <h5 className="mb-0">Details</h5>
                            </Col>
                            <Col xs="auto">
                                <IconButton
                                    iconClassName="fs--2 me-1"
                                    variant="falcon-default"
                                    size="sm"
                                    icon="pencil-alt"
                                >
                                    Update details
                                </IconButton>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="bg-light border-top">
                        <Row>
                            <Col lg xxl={5}>
                                <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                                    Account Information</h6>

                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semi-bold mb-1">ID</p>
                                    </Col>
                                    <Col>
                                        <p className={classNames('mb-1', { 'fst-italic text-400': !CUSTOMER.id })} >
                                            <b>{CUSTOMER.id || ''}</b>
                                        </p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semi-bold mb-1">Email</p>
                                    </Col>
                                    <Col>
                                        <p className={classNames('mb-1', { 'fst-italic text-400': !CUSTOMER.email })} >
                                            <a href={`mailto:${CUSTOMER.email}`}>{CUSTOMER.email}</a>
                                        </p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semi-bold mb-1">Phone</p>
                                    </Col>
                                    <Col>
                                        <p className={classNames('mb-1', { 'fst-italic text-400': !CUSTOMER.phone })} >
                                            <a href={`tel:${CUSTOMER.phone}`}>{CUSTOMER.phone}</a>
                                        </p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={5} sm={4}>
                                        <p className="fw-semi-bold mb-1">Address</p>
                                    </Col>
                                    <Col>
                                        <p className={classNames('mb-1', { 'fst-italic text-400': !CUSTOMER.address })} >
                                            <i>{CUSTOMER.address}</i>
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            {(CUSTOMER && CUSTOMER.company_information) &&
                                <Col lg xxl={{ span: 5, offset: 1 }} className="mt-4 mt-lg-0">
                                    <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                                        Company Information
                                    </h6>

                                    {Object.keys(CUSTOMER.company_information).map((key, value) => (
                                        <Row key={key}>
                                            <Col xs={5} sm={4}>
                                                <p className="fw-semi-bold mb-1">{key}</p>
                                            </Col>
                                            <Col>
                                                {CUSTOMER.company_information[key]}
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                            }
                        </Row>
                    </Card.Body>
                    <Card.Footer className="border-top text-end">
                        <IconButton
                            iconClassName="fs--2 me-1"
                            variant="falcon-default"
                            size="sm"
                            icon="dollar-sign"
                        >
                            Issue Refund
                        </IconButton>
                    </Card.Footer>
                </Card>
                {(CUSTOMER && CUSTOMER.services) && CUSTOMER.services.map(service => <ServiceCard key={service.unique_id} service={service} />)}
            </div>
        </>
    )
}

export default Customer