export const version = '2.0.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent'
};
// export const SOCKET_URL = '//localhost:8080'
export const SOCKET_URL = 'https://atlass-project.ey.r.appspot.com'
export const SOCKET_PATH = '/'

// export const SOCKET_URL = 'https://testapi.atlass.app'
// export const SOCKET_PATH = '/sockets/system'

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
