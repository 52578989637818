import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Container, Card, Col, Form, Row, Table, Placeholder, Alert, ButtonGroup, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import GenericAvatar from 'assets/img/team/avatar.png';
import SimpleBarReact from 'simplebar-react';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEmptySet, faUsers, faKey } from '@fortawesome/pro-duotone-svg-icons';

import APICommunicator from 'api/APICommunicator'


const DataRow = ({
    id,
    name,
    type,
    type_label,
    auth_data,
    URL,
    additional_information,
    actions,
    isLast
}) => {


    return (
        <tr className={classNames({ 'border-bottom border-200': !isLast })}>
            <td className="align-middle fw-bold">{name}</td>
            <td className="align-middle text-center">{type_label}</td>
            <td className="align-middle text-center">{(typeof auth_data === 'object') ? auth_data.map(auth => <code key={auth} className='d-block'>{auth}</code>) : <code>{auth_data}</code>}</td>
            <td className="align-middle text-center"><code className='text-primary'>{URL}</code></td>
            <td className="align-middle text-center">{additional_information}</td>
            <td className="align-middle text-end">
                <ButtonGroup title="Actions">
                    {actions.map(action =>
                        <Button variant="falcon-default" size="sm" onClick={() => { console.log('asd') }} key={action.label}>
                            {action.label}
                        </Button >)}
                </ButtonGroup>
            </td>
        </tr>
    )

}



const AuthenticationComponents = ({ ...props }) => {
    const SampleData = [{
        id: "123123",
        name: "Vonage",
        type: 0,
        type_label: "API Key(s)",
        auth_data: ["2ol3k4jlk2n30p24ijn23f23", "k123jghj1hebj2djn2lws2"],
        URL: "https://vonage.com",
        additional_information: "Used for text messaging functionality",
        actions: [{
            label: "Edit",
            method: "Edit"
        }, {
            label: "Remove",
            method: "Remove"
        }]
    }, {
        id: "634542",
        name: "Google Maps - Frontend",
        type: 0,
        type_label: "API Key(s)",
        auth_data: "2ihj34no23dno2sd23dssd",
        URL: "https://maps.google.com",
        additional_information: "Used for map display",
        actions: [{
            label: "Edit",
            method: "Edit"
        }, {
            label: "Remove",
            method: "Remove"
        }]
    }, {
        id: "21431245",
        name: "Google Maps - Backend",
        type: 0,
        type_label: "API Key(s)",
        auth_data: "dubfas7dfnsen2l34knjdf",
        URL: "https://maps.google.com",
        additional_information: "Used for route and directions calculations",
        actions: [{
            label: "Edit",
            method: "Edit"
        }, {
            label: "Remove",
            method: "Remove"
        }]
    }]

    const { SERVICE } = props;
    const API = new APICommunicator('Services');
    const [data, setData] = useState(SampleData)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => setTimeout(() => {
        setLoading(false)
        setError('Errror')
    }, 1000), [])

    const loadData = () => {
        setData([]);
        setLoading(true);
        setError(false)
        setTimeout(() => {
            setData(SampleData);
            setLoading(false);
        }, 1000)
    }

    return (
        <>
            <Row className="d-flex mb-3 mt-4">
                <Col xs="auto" className="pt-2 ps-4">
                    <FontAwesomeIcon icon={faKey} className="me-0 ms-2" size="2x" />
                </Col>
                <Col className="ps-0">
                    <h5 className="mb-0 mt-1 text-primary position-relative">
                        <span className="bg-200 dark__bg-1100 px-2 rounded ">Auth Components</span>
                        <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
                    </h5>
                    <p className="mb-0 px-2  d-none d-sm-inline-block ">List of keys and components used for auth and API access</p>
                </Col>
                <Col xs='auto'>
                    <IconButton variant="primary" icon="plus" size="sm" transform="shrink-3" disabled>
                        <span className="d-none d-sm-inline-block ms-1" onClick={() => console.log('adding new key')} >Add new</span>
                    </IconButton>
                </Col>
            </Row>
            <Card className="h-100 mt-0">
                <Card.Body className="p-0">
                    <SimpleBarReact>
                        {(loading) ? <Table>
                            <tbody>
                                {[1, 2, 3, 4].map(i => <tr key={i}>
                                    <td><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></td>
                                    <td><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></td>
                                    <td><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></td>
                                </tr>)}
                            </tbody>
                        </Table> :
                            (error) ? <div className="px-4 pt-3"><Alert variant="danger" onClose={() => setError(false)} dismissible>
                                <Alert.Heading>Error loading component!</Alert.Heading>
                                {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                                    (typeof error === 'object') ? error.map(e => <div key={e} style={{ 'whiteSpace': 'pre-line' }}>{e}</div>) : <div style={{ 'whiteSpace': 'pre-line' }}>{error}</div>
                                }
                                <div className="text-center">
                                    <IconButton variant="danger" icon="sync-alt" size="md" transform="shrink-3">
                                        <span className="d-none d-sm-inline-block ms-0" onClick={() => loadData()}>Reload</span>
                                    </IconButton>
                                </div>
                            </Alert></div> :

                                <Table borderless className="mb-0 fs--1 border-200 rounded-3 table-dashboard table-member-info " >
                                    {(data.length >= 1) && <thead className="bg-light">
                                        <tr className="text-900">
                                            <th>Name</th>
                                            <th className="text-center">Type</th>
                                            <th className="text-center">Auth</th>
                                            <th className="text-center">URL</th>
                                            <th className="text-center">Additional Information</th>
                                            <th className="text-end">Actions</th>
                                        </tr>
                                    </thead>}
                                    <tbody>
                                        {data.length >= 1 ? data.map((item, index) => (
                                            <DataRow
                                                {...item}
                                                isLast={index === data.length - 1}
                                                key={item.id}
                                            />
                                        )) : <tr><td><div className="text-400 my-4 text-center">
                                            <FontAwesomeIcon icon={faEmptySet} className="mb-4" size="6x" />
                                            <h5>No Auth Data yet</h5>
                                            <p>No Auth Data for this service yet, use the button below to add one</p>
                                            <IconButton variant="primary" icon="plus" size="md" transform="shrink-3">
                                                <span className="d-none d-sm-inline-block ms-1" onClick={() => console.log('adding new ')}>Add new</span>
                                            </IconButton>
                                        </div></td></tr>}
                                    </tbody>
                                </Table>
                        }
                    </SimpleBarReact>
                </Card.Body>
            </Card>
        </>
    )
}


DataRow.propTypes = {
    // data: PropTypes.shape({
    //     SERVICE_ID: PropTypes.string.isRequired
    // })
};
export default AuthenticationComponents