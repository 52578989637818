import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { Container, Card, Col, Form, Row, Table, Placeholder, Alert, ButtonGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SoftBadge from 'components/common/SoftBadge';
import PropTypes from 'prop-types';
import BasicECharts from 'components/common/BasicEChart';


import * as echarts from 'echarts/dist/echarts.js'
import { getColor } from 'helpers/utils';
import FalconLink from 'components/common/FalconLink';
import GenericAvatar from 'assets/img/team/avatar.png';
import SimpleBarReact from 'simplebar-react';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEmptySet, faUsers, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';

import APICommunicator from 'api/APICommunicator'
import AddServiceUserModal from './AddServiceUserModal';
import EditServiceUserModal from './EditServiceUserModal';
import SimpleConfirmationWindow from 'components/common/SimpleConfirmationWindow';
import { toast } from 'react-toastify';


const API = new APICommunicator('Services');

const UserRow = ({
    uuid,
    firstname,
    lastname,
    profile_picture,
    title,
    isLast,
    role,
    role_label,
    status,
    attendance,
    today,
    thisWeek,
    email,
    username,
    deleted,
    data,
    editHandle,
    confirmModal,
    action_callback,
    SERVICE_ID
}) => {

    const API_URL = `/${SERVICE_ID}/Users/${uuid}`

    const AVATAR = (profile_picture && profile_picture !== ' ') ? <Avatar
        className={``}
        size="2xl"
        src={profile_picture}
        width="50"
        alt={`${firstname} ${lastname}`}
    />
        : <Avatar
            className={``}
            size="2xl"
            name={`${firstname} ${lastname}`}
            width="50"
            alt={`${firstname} ${lastname}`}
        />;


    const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    })

    const handleSuccessWithToast = Response => toast.success(Response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    })

    const handleActionResponse = Response => {
        action_callback(Response.content)
        if (!Response || Response.status !== 'success') return handleErrorWithToast(Response)
        handleSuccessWithToast(Response)
    }

    // Available USER actions depending on Status
    const ALL_ACTIONS = [
        {
            name: 'Edit',
            onClick: () => editHandle(),
            label: 'Edit',
            variant: 'falcon-primary'
        },
        {
            name: 'Activate',
            onClick: () => {

                const TEXT = {
                    title: `Activate ${username}?`,
                    body: 'Are you sure you want to activate this user?',
                    confirmText: 'Activate'
                }

                confirmModal({
                    ...TEXT,
                    show: true,
                    cancelCallback: () => confirmModal({ ...TEXT, show: false }),
                    confirmCallback: () => {
                        confirmModal({ ...TEXT, show: false })
                        API.PUT(`${API_URL}/Activate`)
                            .then(handleActionResponse)
                            .catch(handleErrorWithToast)
                    }
                })

            },
            label: 'Activate',
            variant: 'primary'
        },
        {
            name: 'Disable',
            onClick: () => {

                const TEXT = {
                    title: `Disable ${username}?`,
                    body: 'Are you sure you want to disable this user?',
                    confirmText: 'Disable',
                    type: 'warning'
                }

                confirmModal({
                    ...TEXT,
                    show: true,
                    cancelCallback: () => confirmModal({ ...TEXT, show: false }),
                    confirmCallback: () => {
                        confirmModal({ ...TEXT, show: false })
                        API.PUT(`${API_URL}/Disable`)
                            .then(handleActionResponse)
                            .catch(handleErrorWithToast)
                    }
                })

            },
            label: 'Disable',
            variant: 'falcon-default'
        },
        {
            name: 'Enable',
            onClick: () => {

                const TEXT = {
                    title: `Enable ${username}?`,
                    body: 'Are you sure you want to enable this user?',
                    confirmText: 'Enable'
                }

                confirmModal({
                    ...TEXT,
                    show: true,
                    cancelCallback: () => confirmModal({ ...TEXT, show: false }),
                    confirmCallback: () => {
                        confirmModal({ ...TEXT, show: false })
                        API.PUT(`${API_URL}/Enable`)
                            .then(handleActionResponse)
                            .catch(handleErrorWithToast)
                    }
                })

            },
            label: 'Enable',
            variant: 'falcon-success'
        },
        {
            name: 'Restore',
            onClick: () => {

                const TEXT = {
                    title: `Restore ${username}?`,
                    body: 'Are you sure you want to restore this user?',
                    confirmText: 'Restore'
                }

                confirmModal({
                    ...TEXT,
                    show: true,
                    cancelCallback: () => confirmModal({ ...TEXT, show: false }),
                    confirmCallback: () => {
                        confirmModal({ ...TEXT, show: false })
                        API.PUT(`${API_URL}/Restore`)
                            .then(handleActionResponse)
                            .catch(handleErrorWithToast)
                    }
                })

            },
            label: 'Restore',
            variant: 'success'
        },
        {
            name: 'Delete',
            onClick: () => {

                const TEXT = {
                    title: `Delete ${username}?`,
                    body: 'Are you sure you want to delete this user?',
                    confirmText: 'Delete',
                    type: 'danger'
                }

                confirmModal({
                    ...TEXT,
                    show: true,
                    cancelCallback: () => confirmModal({ ...TEXT, show: false }),
                    confirmCallback: () => {
                        confirmModal({ ...TEXT, show: false })
                        API.DELETE(`${API_URL}`)
                            .then(handleActionResponse)
                            .catch(handleErrorWithToast)
                    }
                })

            },
            label: <FontAwesomeIcon icon={faTrashAlt} />,
            variant: 'falcon-danger'
        }
    ]

    // const minimum = 0
    // const maximum = 3
    // const STATUS = Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    const STATUS = (deleted !== '1') ? status : '3';

    let AVAILABLE_ACTIONS = []

    if (STATUS === '0') AVAILABLE_ACTIONS = ALL_ACTIONS.filter(action => ['Edit', 'Disable', 'Delete'].includes(action.name))   // ACTIVE
    if (STATUS === '1') AVAILABLE_ACTIONS = ALL_ACTIONS.filter(action => ['Edit', 'Enable', 'Delete'].includes(action.name))    // DISABLED
    if (STATUS === '2') AVAILABLE_ACTIONS = ALL_ACTIONS.filter(action => ['Activate', 'Delete'].includes(action.name))          // PEDNING ACTIVATION
    if (STATUS === '3') AVAILABLE_ACTIONS = ALL_ACTIONS.filter(action => ['Restore'].includes(action.name))                     // DELETED

    return (
        <tr className={classNames({ 'border-bottom border-200': !isLast })}>
            <td>
                <Flex alignItems="center" className="position-relative">
                    {AVATAR}
                    <div className="flex-1 ms-3">
                        <h6 className="mb-0 fw-semi-bold">
                            <Link className="text-dark stretched-link" to="#!">
                                {firstname} {lastname}
                            </Link>
                        </h6>
                        <p className="fs--2 mb-0 text-500">{title}</p>
                    </div>
                </Flex>
            </td>
            <td className="align-middle text-center fw-semi-bold">
                <pre>{role_label || 'role'}</pre>
            </td>
            <td className="align-middle text-center fw-semi-bold">
                <pre>{username || 'user.name'}</pre>
            </td>
            <td className="align-middle text-center fw-semi-bold">
                <a href={`mailto:${email || ''}`}>
                    {email || 'sample@email.com'}
                </a>
            </td>
            <td className="align-middle text-end">
                <ButtonGroup title="Actions">
                    {AVAILABLE_ACTIONS.map(ACTION =>
                        <Button key={ACTION.label} variant={ACTION.variant} size="sm" onClick={ACTION.onClick}>
                            {ACTION.label}
                        </Button>
                    )}
                </ButtonGroup>
            </td>
        </tr>
    );
};

const ServiceUsersList = ({ ...props }) => {

    const { SERVICE_ID, SERVICE_LABEL } = props;
    const [serviceUsers, setServiceUsers] = useState([])
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    // Add User Modal
    const [addServiceUserModalShow, setAddServiceUserModalShow] = useState(false);

    // Edit User Modal
    const [editServiceUserModalShow, setEditServiceUserModalShow] = useState(false);
    const [editServiceUserModalData, setEditServiceUserModalData] = useState({});

    // Confirmation Modal
    const [confirmationModalData, setConfirmationModalData] = useState({
        show: false,
        titel: 'Confirm',
        body: 'Are you sure you want to continue with this action?',
        confirmCallback: () => {
            console.log('confirmed')
        },
        cancelCallback: () => {
            console.log('cancelled')
        }
    })


    const loadData = () => {
        setError(false)
        setLoading(true)
        API.GET(`/${SERVICE_ID}/Users`)
            .then(Response => {
                setLoading(false)
                if (!Response || Response.status !== 'success') return handleError(Response)
                setServiceUsers(Response.content)
            })
            .catch(handleError)
    }

    const handleError = Error => setError(API.handleResponseErrors(Error))


    useEffect(() => {
        loadData()
    }, [])



    return (
        <>
            <Row className="d-flex mb-3 mt-4">
                <Col xs="auto" className="pt-2 ps-4">
                    <FontAwesomeIcon icon={faUsers} className="me-0 ms-2" size="2x" />
                </Col>
                <Col className="ps-0">
                    <h5 className="mb-0 mt-1 text-primary position-relative">
                        <span className="bg-200 dark__bg-1100 px-2 rounded">Service Users</span>
                        <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
                    </h5>
                    <p className="mb-0 px-2 d-none d-sm-inline-block">List of users assigned to this service</p>
                </Col>
                <Col xs='auto'>
                    <IconButton variant="primary" icon="plus" size="sm" transform="shrink-3">
                        <span className="d-none d-sm-inline-block ms-1" onClick={() => setAddServiceUserModalShow(true)}>Add new user</span>
                    </IconButton>
                </Col>
            </Row>
            <Card className="h-100 mt-0">
                <Card.Body className="p-0">
                    <SimpleBarReact>
                        {(loading) ? <Table>
                            <tbody>
                                {[1, 2, 3, 4].map(i => <tr key={i}>
                                    <td><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></td>
                                    <td><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></td>
                                    <td><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></td>
                                </tr>)}
                            </tbody>
                        </Table> :
                            (error) ? <div className="px-4 pt-3"><Alert variant="danger" onClose={() => setError(false)} dismissible>
                                <Alert.Heading>Error loading component!</Alert.Heading>
                                {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                                    (typeof error === 'object') ? error.map(e => <div key={e} style={{ 'whiteSpace': 'pre-line' }}>{e}</div>) : <div style={{ 'whiteSpace': 'pre-line' }}>{error}</div>
                                }
                                <div className="text-center">
                                    <IconButton variant="danger" icon="sync-alt" size="md" transform="shrink-3">
                                        <span className="d-none d-sm-inline-block ms-0" onClick={() => loadData()}>Reload</span>
                                    </IconButton>
                                </div>
                            </Alert></div> :
                                <Table borderless className="mb-0 fs--1 border-200 rounded-3 table-dashboard table-member-info" >
                                    {(serviceUsers.length >= 1) && <thead className="bg-light">
                                        <tr className="text-900">
                                            <th>Users</th>
                                            <th className="text-center">Role</th>
                                            <th className="text-center">Username</th>
                                            <th className="text-center">Email</th>
                                            <th className="text-end"></th>
                                        </tr>
                                    </thead>}
                                    <tbody>
                                        {serviceUsers.length >= 1 ? serviceUsers.map((user, index) => (
                                            <UserRow
                                                {...user}
                                                isLast={index === serviceUsers.length - 1}
                                                key={user.id}
                                                SERVICE_ID={SERVICE_ID}
                                                editHandle={() => {
                                                    const U = user;
                                                    delete U.role_label
                                                    setEditServiceUserModalData(U)
                                                    setTimeout(() => setEditServiceUserModalShow(true), 50)
                                                }}
                                                confirmModal={data => {

                                                    const DATA = {
                                                        ...confirmationModalData,
                                                        ...data
                                                    }

                                                    setConfirmationModalData(DATA)
                                                }}
                                                action_callback={() => {
                                                    loadData()
                                                }}
                                            />
                                        )) : <tr><td><div className="text-400 my-4 text-center">
                                            <FontAwesomeIcon icon={faEmptySet} className="mb-4" size="6x" />
                                            <h5>No users yet</h5>
                                            <p>No ussers added for this service yet, use the button below to add a user</p>
                                            <IconButton variant="primary" icon="plus" size="md" transform="shrink-3">
                                                <span className="d-none d-sm-inline-block ms-1" onClick={() => setAddServiceUserModalShow(true)}>Add new user</span>
                                            </IconButton>
                                        </div></td></tr>}
                                    </tbody>
                                </Table>
                        }
                    </SimpleBarReact>
                </Card.Body>
            </Card>

            <AddServiceUserModal
                SERVICE_ID={SERVICE_ID}
                show={addServiceUserModalShow}
                handleClose={() => { setAddServiceUserModalShow(false) }}
                successCallback={() => { loadData() }} />

            <EditServiceUserModal
                SERVICE_ID={SERVICE_ID}
                inheritedFormData={editServiceUserModalData}
                show={editServiceUserModalShow}
                handleClose={() => { setEditServiceUserModalShow(false) }}
                successCallback={() => { loadData() }}
            />

            <SimpleConfirmationWindow {...confirmationModalData} />
        </>
    )
}

UserRow.propTypes = {
    // id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    // name: PropTypes.string.isRequired,
    // status: PropTypes.string.isRequired,
    // isLast: PropTypes.bool,
    // img: PropTypes.string.isRequired,
    // role: PropTypes.string.isRequired,
    // attendance: PropTypes.shape({
    //     text: PropTypes.string.isRequired,
    //     color: PropTypes.string.isRequired
    // }),
    // today: PropTypes.shape({
    //     amount: PropTypes.number.isRequired,
    //     color: PropTypes.string.isRequired,
    //     time: PropTypes.string.isRequired
    // }),
    // thisWeek: PropTypes.shape({
    //     amount: PropTypes.number.isRequired,
    //     color: PropTypes.string.isRequired,
    //     time: PropTypes.string.isRequired
    // }),
    // data: PropTypes.array.isRequired
};

ServiceUsersList.propTypes = {
    data: PropTypes.shape({
        SERVICE_ID: PropTypes.string.isRequired
    })
};
export default ServiceUsersList