import React, { useState, useRef, useEffect } from 'react';
import { Form, Modal, Button, Col, Row, FloatingLabel, InputGroup, Alert } from 'react-bootstrap'
import Lottie from 'react-lottie';
import questionAnimation from 'assets/img/animated-icons/65842-question-bubble-bottom-sheet'
import warningAnimation from 'assets/img/animated-icons/warning-light'


const SimpleConfirmationWindow = ({ show, confirmCallback, cancelCallback, title, body, cancelText, confirmText, type }) => {

    let animationIcon = questionAnimation
    let confirmButtonVariant = "primary"
    let loop = true
    switch (type) {
        case 'default':
            animationIcon = questionAnimation
            loop = true
            confirmButtonVariant = "primary"
            break;
        case 'warning':
            animationIcon = warningAnimation
            loop = false
            confirmButtonVariant = "warning"
            break;
        case 'danger':
            animationIcon = warningAnimation
            loop = true
            confirmButtonVariant = "danger"
            break;
        default:
            break;
    }

    const defaultAnimationOptions = {
        loop: loop,
        autoplay: true,
        animationData: animationIcon,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const handleCancel = e => {
        e.preventDefault()
        // setShowModal(false)
        if (typeof cancelCallback === 'function') cancelCallback()
    }

    const handleConfirm = e => {
        e.preventDefault()
        // setShowModal(false)
        if (typeof confirmCallback === 'function') confirmCallback()
    }

    // useEffect(() => { console.log(show); setShowModal(show) }, [show])
    return (

        <Modal
            show={show}
            onHide={() => { }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>

                <div className="">
                    <Lottie options={defaultAnimationOptions} width={300} />
                    <div className="w-100 text-center mt-4">
                        <h3>{title ? title : `Confirm`}</h3>
                        <div>{body ? <p>{body}</p> : <p>Are you sure you want to continue with this action?</p>}</div>
                    </div>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="falcon-secondary"
                    onClick={handleCancel}>
                    {cancelText || `No`}
                </Button>
                <Button
                    className="ms-4"
                    variant={confirmButtonVariant}
                    onClick={handleConfirm}>
                    {confirmText || `Yes`}
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

export default SimpleConfirmationWindow