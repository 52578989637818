import React, { useEffect, useState } from 'react';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgrImages from './randomBgr';
import ActivateForm from '../ActivateForm';
import {
  Link,
  useHistory,
  useParams
} from "react-router-dom";
import Lottie from 'react-lottie';
import welcomeAnimation from 'assets/img/animated-icons/49654-welcome-kit'
import invalidAnimation from 'assets/img/animated-icons/54117-invalid'
import successAnimation from 'assets/img/animated-icons/88860-success-animation'
import { useAuth } from 'context/AuthContext'
import APICommunicator from 'api/APICommunicator'
import { Spinner, Alert, Collapse } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

const API = new APICommunicator('asd');

const Activate = () => {

  const { AUTH_KEY } = useParams();
  const { check_activation_key, activate_user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false)
  const [welcomeName, setWelcomeName] = useState(false)
  var randomBgr = bgrImages[Math.floor(Math.random() * bgrImages.length)];
  const [activationSuccessful, setActivationSuccessful] = useState(false)
  const [activationLoading, setActivationLoading] = useState(false)

  const welcomeAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: welcomeAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const errorAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: invalidAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const successAnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // KEY VALIDATION

  const handleKeyCheckError = Response => setError(API.handleResponseErrors(Response))

  const handleKeyCheckResponse = Response => {
    setLoading(false)
    if (!Response || Response.status !== 'success') return handleKeyCheckError(Response)
    setWelcomeName(Response?.content?.division_name)
  }

  useEffect(() => {
    check_activation_key(AUTH_KEY)
      .then(handleKeyCheckResponse)
      .catch(handleKeyCheckError)
  }, []);


  // ACTIVATION FORM SUBMIT

  const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response), {
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  })

  const handleSuccessWithToast = Response => toast.success(Response.message, {
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  })


  const handleActivationResponse = Response => {
    setActivationLoading(false)
    if (!Response || Response.status !== 'success') return handleErrorWithToast(Response)
    setActivationSuccessful(true)
    // handleSuccessWithToast(Response)
  }
  const handleActivationSubmit = formData => {
    setActivationLoading(true)
    console.log(formData)
    setTimeout(() => {
      activate_user({
        ...formData,
        'activation_code': AUTH_KEY
      })
        .then(handleActivationResponse)
        .catch(handleErrorWithToast)
    }, 1000)
  }




  // B!ly@n@_K0m@r0v@
  return (
    <AuthSplitLayout
      bgProps={{ image: randomBgr, position: '50% 76%', overlay: true }}
    >
      {loading ? <div className="text-center"><Spinner animation="border" variant="primary" /></div> :

        error ?
          <div>
            <div><Lottie options={errorAnimationOptions} /></div>
            <Alert variant="danger">We're sorry, this activation link is either invalid or expired.</Alert>
            <Link to="/login"> &larr; Already activated? Try logging in </Link>
          </div> :
          <>

            <Collapse in={!activationSuccessful}>

              <div className="text-center">
                <div><Lottie options={welcomeAnimationOptions} /></div>
                {welcomeName && <h2 className="mb-0 fw-bold">{welcomeName}</h2>}
                <h4 className="mb-0">Welcome to A.T.L.A.S.S.</h4>
                <small>Please fill out the form below to activate your account</small>
                <ActivateForm handleActivationSubmit={handleActivationSubmit} loading={activationLoading} />
              </div>

            </Collapse>
            <Collapse in={activationSuccessful}>
              <div className="text-center" id="activationSuccess">
                <div><Lottie options={successAnimationOptions} /></div>
                <h3 className="mb-0 fw-bold">Congratulations {welcomeName}!</h3>
                <p>You've successfully activated your account. You can now use A.T.L.A.S.S.</p>
                <Link to="/login"><Button variant="primary">Login</Button></Link>
              </div>
            </Collapse>
          </>

      }
    </AuthSplitLayout>
  );
};

export default Activate;
