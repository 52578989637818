import { isAuthenticated, getUserData, getAccessToken, refreshAccessToken, Logout, isAccesstokenExpired } from "../context/AuthContext"

export const API_VERSION = 'v2' || process.env.REACT_APP_API_VERSION
export const BASE_URL = process.env.REACT_APP_API_URL + API_VERSION + '/Admin'
export const BASE_URL_UNAUTHENTICATED = process.env.REACT_APP_API_URL + API_VERSION + '/Admin'


export default function APICommunicator(Endpoint = null) {


    const ENDPOINT = (Endpoint) ? `/${Endpoint}` : '';

    this.v2 = ({ Method = null, Query = null, Payload = {}, Headers = {} }) => {

        var METHOD = 'GET';
        switch (Method) {
            case 'ADD':
            case 'POST':
            case 'CREATE':
            case 'UPLOAD':
                METHOD = 'POST'; // ADD NEW
                break;
            case 'PUT':
            case 'EDIT':
            case 'UPDATE':
                METHOD = 'PUT'; // UPDATE EXISTING
                break;
            case 'DELETE':
            case 'REMOVE':
                METHOD = 'DELETE'; // DELETE
                break;
            case '':
            case 'GET':
            case 'HEAD':
            default:
                METHOD = 'GET'// GET
                break;
        }

        const QUERY = (Query) ? `${Query}` : '';

        // const PAYLOAD = JSON.stringify(Payload);
        const PAYLOAD = (Method !== 'UPLOAD') ? JSON.stringify(Payload) : Payload;

        const AUTHENTICATION_HEADERS = isAuthenticated() ? {
            'AccessToken': getAccessToken(),
            'ServiceUniqueID': JSON.parse(getUserData()).service_unique_id
        } : {}

        const METHOD_DEFINED_HEADERS = (Method !== 'UPLOAD') ? {
            'Content-Type': 'application/json; charset=utf-8'
        } : {}

        const HEADERS = {
            'Accept': 'application/json',
            ...METHOD_DEFINED_HEADERS,
            ...AUTHENTICATION_HEADERS
        };

        const METHOD_DEPENDANT_OPTIONS = (METHOD !== 'GET') ? {
            body: PAYLOAD
        } : {}

        const OPTIONS = {
            method: METHOD,
            mode: "cors",
            headers: HEADERS,
            ...METHOD_DEPENDANT_OPTIONS
        }

        return fetch(BASE_URL + ENDPOINT + QUERY, OPTIONS)
            .then(this.handleResponse)
    }


    this.fetchQuery = (apiVersion, payloadData) => {

        if (typeof this[apiVersion] !== 'function') return console.log('Unknown API Version');

        this.lastQuery = this[apiVersion]
        this.lastQueryPayload = payloadData

        if (isAccesstokenExpired()) return refreshAccessToken().then(() => { return this[apiVersion](payloadData) })

        return this[apiVersion](payloadData);
    }

    this.handleResponseErrors = Response => {

        if (!Response) return 'Unknown error'

        if (typeof Response === 'string') return Response

        if (!Response.details && !Response.message) return 'Unknown error'

        if (!Response.details) return Response.message

        let ErrorHTML = ''
        Object.entries(Response.details).forEach((key, value) => ErrorHTML += `${key[0]}: ${key[1]} \n `)

        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') console.log(ErrorHTML)

        return ErrorHTML

    }

    this.handleResponse = async (Response) => {

        const lastQuery = this.lastQuery
        const lastPayload = this.lastQueryPayload

        // return (await Response.json())
        if (Response.ok) return Response.json()

        /* ERROR HANDLING */
        if (Response.status === 401) {
            var CONTENT = await Response.json()
            return (CONTENT.details.error === 'accessToken') ? refreshAccessToken().then(() => { return lastQuery(lastPayload) }) : Logout()
        }
        if (Response.status === 400) return Response.json() // Bad Request / Missing Parameters
        if (Response.status === 403) return Response.json() // No permissions
        if (Response.status === 404) return Response.json() // 
        if (Response.status === 409) return Response.json() // Conflict request 


        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') console.log(
            Response,
            {
                'lastQuery': lastQuery,
                'lastPayload': lastPayload
            }
        )

        return Response.json() 

    }



    return {
        GET: (Query, Payload) => { return this.fetchQuery(API_VERSION, { Method: 'GET', Query: Query, Payload: Payload }) },
        // --
        POST: (Query, Payload) => { return this.fetchQuery(API_VERSION, { Method: 'POST', Query: Query, Payload: Payload }) },
        UPLOAD: (Query, Payload) => { return this.fetchQuery(API_VERSION, { Method: 'UPLOAD', Query: Query, Payload: Payload }) },
        // --
        PUT: (Query, Payload) => { return this.fetchQuery(API_VERSION, { Method: 'PUT', Query: Query, Payload: Payload }) },
        EDIT: (Query, Payload) => { return this.fetchQuery(API_VERSION, { Method: 'EDIT', Query: Query, Payload: Payload }) },
        UPDATE: (Query, Payload) => { return this.fetchQuery(API_VERSION, { Method: 'UPDATE', Query: Query, Payload: Payload }) },
        // -- 
        DELETE: (Query, Payload) => { return this.fetchQuery(API_VERSION, { Method: 'DELETE', Query: Query, Payload: Payload }) },
        REMOVE: (Query, Payload) => { return this.fetchQuery(API_VERSION, { Method: 'REMOVE', Query: Query, Payload: Payload }) },
        // --
        CUSTOM: (Method, Query, Payload) => { return this.fetchQuery(API_VERSION, { Method: Method, Query: Query, Payload: Payload }) },
        QUERY: (Method, Query, Payload) => { return this.fetchQuery(API_VERSION, { Method: Method, Query: Query, Payload: Payload }) },
        handleResponseErrors: Response => { return this.handleResponseErrors(Response) }
    }
}