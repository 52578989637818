
const Users = [
  {
    placeholder: true,  // for loading purposes
    id: 1,
    firstname: 'Rado',
    lastname: 'Trenev',
    timezone: 'Europe/Sofia',
    profileLink: '/Users/Profile/1',
    address_1: "8774 Yates Dr, #330",
    address_2: "",
    city: "Westminster",
    email: "trenev@atlassapp.com",
    phone: "(855) 993-2453 * 305",
    profile_picture: "",
    role: "1",
    state: "Colorado",
    title: "Never Settle",
    username: "ttrenevjr",
    zip: "80031"
  },
  {
    placeholder: true,  // for loading purposes
    id: 2,
    firstname: 'Rado',
    lastname: 'Trenev',
    timezone: 'Europe/Sofia',
    profileLink: '/Users/Profile/1',
    address_1: "8774 Yates Dr, #330",
    address_2: "",
    city: "Westminster",
    email: "trenev@atlassapp.com",
    phone: "(855) 993-2453 * 305",
    profile_picture: "",
    role: "1",
    state: "Colorado",
    title: "Never Settle",
    username: "ttrenevjr",
    zip: "80031"
  },
  {
    placeholder: true,  // for loading purposes
    id: 3,
    firstname: 'Rado',
    lastname: 'Trenev',
    timezone: 'Europe/Sofia',
    profileLink: '/Users/Profile/1',
    address_1: "8774 Yates Dr, #330",
    address_2: "",
    city: "Westminster",
    email: "trenev@atlassapp.com",
    phone: "(855) 993-2453 * 305",
    profile_picture: "",
    role: "1",
    state: "Colorado",
    title: "Never Settle",
    username: "ttrenevjr",
    zip: "80031"
  },
  {
    placeholder: true,  // for loading purposes
    id: 4,
    firstname: 'Rado',
    lastname: 'Trenev',
    timezone: 'Europe/Sofia',
    profileLink: '/Users/Profile/1',
    address_1: "8774 Yates Dr, #330",
    address_2: "",
    city: "Westminster",
    email: "trenev@atlassapp.com",
    phone: "(855) 993-2453 * 305",
    profile_picture: "",
    role: "1",
    state: "Colorado",
    title: "Never Settle",
    username: "ttrenevjr",
    zip: "80031"
  },
  {
    placeholder: true,  // for loading purposes
    id: 5,
    firstname: 'Rado',
    lastname: 'Trenev',
    timezone: 'Europe/Sofia',
    profileLink: '/Users/Profile/1',
    address_1: "8774 Yates Dr, #330",
    address_2: "",
    city: "Westminster",
    email: "trenev@atlassapp.com",
    phone: "(855) 993-2453 * 305",
    profile_picture: "",
    role: "1",
    state: "Colorado",
    title: "Never Settle",
    username: "ttrenevjr",
    zip: "80031"
  }
];
export default Users