import React, { useState, useRef } from 'react';
import {
    useHistory,
    useParams
} from "react-router-dom";
import { Card, Col, Form, Row, OverlayTrigger, Tooltip, Placeholder, Button, DropdownButton, Dropdown, InputGroup, Alert, FloatingLabel, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle, faCaretCircleRight, faPauseCircle, faTimesCircle, faDatabase, faUsers, faHdd, faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { toast } from 'react-toastify';
import APICommunicator from 'api/APICommunicator'
import { isAuthenticated, getUserData } from 'context/AuthContext';

const UserData = isAuthenticated() ? JSON.parse(getUserData()) : false

export const ServiceStatusRenderer = ({ status }) => {
    let STATUS = '';
    let ACTIONS = [];

    switch (status) {
        case '0':
            STATUS = <OverlayTrigger overlay={<Tooltip>Pending activation</Tooltip>}><span><FontAwesomeIcon className="text-primary" icon={faDotCircle} size="2x" /></span></OverlayTrigger>
            ACTIONS = [
                {
                    label: 'Activate',
                    method: 'Activate',
                },
                {
                    label: 'Terminate',
                    method: 'Terminate',
                }
            ]
            break;
        case '1':
            STATUS = <OverlayTrigger overlay={<Tooltip>Active</Tooltip>}><span><FontAwesomeIcon className="text-success" icon={faCaretCircleRight} size="2x" /></span></OverlayTrigger>
            ACTIONS = [
                {
                    label: 'Suspend',
                    method: 'Suspend',
                },
                {
                    label: 'Terminate',
                    method: 'Terminate',
                }
            ]
            break;
        case '2':
            STATUS = <OverlayTrigger overlay={<Tooltip>Suspended</Tooltip>}><span><FontAwesomeIcon className="text-warning" icon={faPauseCircle} size="2x" /></span></OverlayTrigger>
            ACTIONS = [
                {
                    label: 'Resume',
                    method: 'Resume',
                },
                {
                    label: 'Terminate',
                    method: 'Terminate',
                }
            ]
            break;
        case '3':
            STATUS = <OverlayTrigger overlay={<Tooltip>Terminated</Tooltip>}><span><FontAwesomeIcon className="text-danger" icon={faTimesCircle} size="2x" /></span></OverlayTrigger>
            ACTIONS = [
                {
                    label: 'Reinstate',
                    method: 'Activate',
                }
            ]
            break;
        case '9':
            STATUS = <OverlayTrigger overlay={<Tooltip>Activating</Tooltip>}><span><FontAwesomeIcon className="text-primary fa-spin" icon={faSpinnerThird} size="2x" /></span></OverlayTrigger>
            ACTIONS = []
            break;
        default:
            break;
    }
    return {
        Component: STATUS,
        Actions: ACTIONS
    }
}


const ServiceCard = ({ service }) => {
    const API = new APICommunicator('Services');
    const TFA_API = new APICommunicator('AccessCodes');
    const { database_size, bucket_size, active_users, status, unique_id, customer_uuid, customer_name, label, placeholder, actionCallback } = service;
    const [toastId, setToastId] = useState('');
    const [formData, setFormData] = useState({})
    const [modalError, setModalError] = useState('')
    const [modalShow, setModalShow] = useState(false)
    const [action, setAction] = useState()

    const STATUS = ServiceStatusRenderer({ status: status })
    // const ACTIONS = ServiceStatusRenderer({ status: status }).Actions


    const actionHandler = a => {

        // OTHER ACTIONS

        if (a.method !== 'Activate') return API.POST(`/${unique_id}/${a.method}`)
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleErrorWithToast(Response)
                if (typeof actionCallback === 'function') return actionCallback()
            }).catch(handleErrorWithToast)

        // ACTIVATION ALGORITHM

        setModalShow(true);
        setAction(a)

        TFA_API.POST(`/Request`, {
            requested_for: 'Service Activation',
            service_unique_id: unique_id
        })
            .then(Response => { if (!Response || Response.status !== 'success') return handleErrorInModal(Response) })
            .catch(handleErrorInModal)

    }


    const submitHandler = e => {

        e.preventDefault();

        API.POST(`/${unique_id}/${action.method}`, formData)
            .then(Response => {
                if (!Response || Response.status !== 'success') return handleErrorInModal(Response)

                toast.success(Response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                })
                setModalShow(false);
                if (typeof actionCallback === 'function') return actionCallback()
            })
            .catch(handleErrorInModal)
    }

    const handleErrorWithToast = Response => toast.error(API.handleResponseErrors(Response), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    })

    const handleErrorInModal = Response => setModalError(API.handleResponseErrors(Response))

    const handleFieldFormChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    return (
        <>
            <Card className="d-flex p-3 h-100 w-100 mb-2">
                <Row>
                    <Col className="text-start col-auto">
                        {(!placeholder) && <div className="text-muted d-none d-xs-none d-sm-none d-md-block d-lg-none d-xl-none d-xxl-none fs--2 text-500">Status</div>}
                        {(placeholder) ? <div className="w-100"><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></div> :
                            STATUS.Component
                        }
                    </Col>
                    <Col className="text-start text-nowrap">
                        {(!placeholder) && <div className="text-muted d-none d-xs-none d-sm-none d-md-block d-lg-none d-xl-none d-xxl-none fs--2 text-500 ms-3">Label</div>}
                        {(placeholder) ? <div className="w-100"><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></div> :
                            <Link className="bg-200 dark__bg-1100 px-3 py-1 rounded-pill mb-0 d-inline-block fs--1" to={`/Services/${unique_id}`}>{label || unique_id}</Link>
                        }
                    </Col>
                    <Col className="text-start text-nowrap d-none d-md-block">
                        {(!placeholder) && <div className="text-muted d-lg-none d-xl-none d-xxl-none fs--2 text-500">Usage</div>}
                        {(placeholder) ? <div className="w-100"><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></div> :
                            <div className="mt-md-1">
                                <div className="d-inline-block me-3">{database_size && <OverlayTrigger overlay={<Tooltip>Database Size</Tooltip>}><span><FontAwesomeIcon icon={faDatabase} className="me-1" />{database_size}</span></OverlayTrigger>}</div>
                                <div className="d-inline-block me-3">{bucket_size && <OverlayTrigger overlay={<Tooltip>Bucket Storage Size</Tooltip>}><span><FontAwesomeIcon icon={faHdd} className="me-1" />{bucket_size}</span></OverlayTrigger>}</div>
                                <div className="d-inline-block">{active_users && <OverlayTrigger overlay={<Tooltip>Service Users</Tooltip>}><span><FontAwesomeIcon icon={faUsers} className="me-1" />{active_users} </span></OverlayTrigger>}</div>
                            </div>
                        }
                    </Col>
                    <Col className="text-start text-lg-center text-nowrap d-none d-md-block">
                        {(!placeholder) && <div className="text-muted d-lg-none d-xl-none d-xxl-none fs--2 text-500">Customer</div>}
                        {(placeholder) ? <div className="w-100"><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></div> :
                            <div className="mt-md-1"><Link to={`/Customers/${customer_uuid}`}>{customer_name}</Link></div>
                        }
                    </Col>
                    <Col className="text-right text-nowrap pt-md-2 pt-lg-0 col-auto">
                        {(placeholder) ? <div className="w-100"><Placeholder animation="glow"><Placeholder xs={12} /></Placeholder></div> :
                            <DropdownButton variant="falcon-primary" size="sm" title="Actions">
                                {STATUS.Actions.map(action => <Dropdown.Item eventKey="1" onClick={() => { actionHandler(action) }} key={action.label}>{action.label}</Dropdown.Item>)}
                            </DropdownButton>
                        }
                    </Col>
                </Row>
            </Card>

            <Modal
                show={modalShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                onClose={() => { setFormData({}) }}
                centered >
                <Form onSubmit={submitHandler}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Security Verification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            We have sent an email to <b>{UserData.email}</b> with an <b>Access Code</b> to verify your identity and prevent accidental executions.
                            Please enter the code you received to confirm and execute the action
                        </p>
                        <Row>
                            <Col>
                                <FloatingLabel controlId="floatingInput" label="Security Code" className="mb-3">
                                    <Form.Control type="text" placeholder="Security Code" name="access_code" onChange={handleFieldFormChange} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {modalError && <Alert variant="warning" dismissible onClose={() => setModalError(false)} dismissible>
                            {(typeof modalError === 'object') ? modalError.map(e => <div key={e}>{e}</div>) : <div>{modalError}</div>}
                        </Alert >
                        }

                    </Modal.Body>

                    <Modal.Footer>
                        <Form.Group>
                            <Button
                                variant="falcon-secondary"
                                onClick={() => { setModalShow(false) }}>
                                Close
                            </Button>
                            <Button
                                type="submit"
                                className="ms-4"
                                variant="primary"
                                disabled={!formData.access_code}>
                                {action?.label} {label}
                            </Button>
                        </Form.Group>
                    </Modal.Footer>

                </Form>
            </Modal>
        </>
    );
};

ServiceCard.propTypes = {
    service: PropTypes.shape({
        database_size: PropTypes.string,
    })
};

export default ServiceCard;
