import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext'
import PrivateRoute from "./routes/PrivateRoutes"
import Login from "./components/authentication/split/Login"
import ForgottenPassword from "./components/authentication/split/ForgottenPassword"
import ResetPassword from "./components/authentication/split/ResetPassword"
import Layout from './layouts/Layout';
import Activate from 'components/authentication/split/Activate';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/Activate/:AUTH_KEY" component={Activate} />
          <Route exact path="/ForgottenPassword" component={ForgottenPassword} />
          <Route exact path="/ResetPassword/:AUTH_KEY" component={ResetPassword} />
          <PrivateRoute path="/" component={Layout} />
        </Switch>
      </AuthProvider>
    </Router>
  );
};

export default App;
