import React, { useState, useEffect } from 'react';
import APICommunicator from 'api/APICommunicator'
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEmptySet } from '@fortawesome/pro-duotone-svg-icons';
import IconButton from 'components/common/IconButton';
import ServiceCard from './ServiceCard';
import AddServiceModal from './modals/AddServiceModal';

const Services = () => {
    const API = new APICommunicator('Services');
    const [list, setList] = useState([{ placeholder: true }, { placeholder: true }, { placeholder: true }, { placeholder: true }, { placeholder: true }]);
    const [modalShow, setModalShow] = useState(false);
    const [options] = useState([
        { name: 'Pending Activation', value: 0 },
        { name: 'Active', value: 1 },
        { name: 'Suspended', value: 2 },
        { name: 'Terminated', value: 3 }

    ]);
    const [searchField, setSearchField] = useState('');
    const [selectField, setSelectField] = useState('');
    const [loading, setLoading] = useState(true);


    const List = () => {
        const QUERY = [{
            key: 'search',
            value: searchField
        }, {
            key: 'status',
            value: selectField
        }];

        const queryString = QUERY.map((q, i) => {
            let operator = (i == 0) ? "?" : "&"
            return `${operator}${q['key']}=${encodeURIComponent(q['value'])}`
        }).join('')

        setList([]) // sample users to show loading 

        API.GET(`/${queryString}`)
            .then(Response => {
                const LIST = Response.content
                if (Array.isArray(LIST)) setList(LIST)
                if (!Array.isArray(LIST)) setList([])
                setLoading(false)

            })
            .catch(e => console.log(e))
    }


    useEffect(() => {
        setLoading(true)
        List()
    }, [searchField, selectField])

    useEffect(() => {
        setLoading(true)
        List()
    }, [])



    return (
        <>
            <div className="mt-2">
                <div>
                    <Row className="align-items-center">
                        <Col>
                            <IconButton
                                onClick={() => setModalShow(true)}
                                variant="falcon-default"
                                size="sm"
                                icon="plus"
                                transform="shrink-3">
                                <span className="d-none d-sm-inline-block ms-1">New Service</span>
                            </IconButton>
                        </Col>
                        <Col>
                            <Row className="g-2">
                                <Col>
                                    <Form.Control placeholder="Search..." size="sm" onKeyUp={(e) => { setSearchField(e.target.value) }} />
                                </Col>
                                <Col className="d-md-block d-none">
                                    <Form.Select size="sm" onChange={(e) => { setSelectField(e.target.value) }}>
                                        <option value="">All services</option>
                                        {options.map(option => (
                                            <option key={option.name} value={option.value}>{option.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4 px-1 pb-1 pt-0 fs--1">
                    {(!loading && (list.length >= 1)) ? <Row className="mb-2 d-none d-lg-flex">
                        <Col className="text-start col-auto text-500">Status</Col>
                        <Col className="text-start text-500"><span className="ms-2">Label</span></Col>
                        <Col className="text-start text-500">Usage</Col>
                        <Col className="text-start text-lg-center text-500">Customer</Col>
                        <Col className="text-right col-auto pr-4 text-500"><span className="me-5">Actions</span></Col>
                    </Row> : ''}
                    {loading ? [1, 2, 3].map(i => <ServiceCard key={i} service={{ placeholder: true }} />) :
                        (list.length >= 1) ? list.map(service =>
                            <ServiceCard key={service.unique_id || (Math.floor(Math.random() * (99999 - 1 + 1)) + 1)} service={{ ...service, actionCallback: () => { List() } }} />
                        )
                            :
                            <div className="text-400 text-center my-4">
                                <FontAwesomeIcon icon={(searchField === '' && selectField === '') ? faEmptySet : faSearch} className="mb-4" size="6x" />
                                {(searchField === '' && selectField === '') ? <h5>No services</h5> : <h5>No results</h5>}
                                <p>You can add a new service by clicking the button below</p>
                                <IconButton
                                    variant="primary"
                                    icon="plus"
                                    size="md"
                                    transform="shrink-3"
                                >
                                    <span className="d-none d-sm-inline-block ms-1" onClick={() => setModalShow(true)}>Add new service</span>
                                </IconButton>
                            </div>

                    }
                </div>
            </div>
            <AddServiceModal show={modalShow} handleClose={() => { setModalShow(false) }} successCallback={() => { List() }} />
        </>
    );
};

export default Services;