import React, { useState } from 'react';
import ForgottenPasswordForm from 'components/authentication/ForgottenPasswordForm';
import bgImg from 'assets/img/generic/17.jpg';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgrImages from './randomBgr';
import Lottie from 'react-lottie';
import ForgotPasswordAnimation from 'assets/img/animated-icons/94132-forgot-password'
import emailSentAnimation from 'assets/img/animated-icons/87580-email-icon-animation'
import { Button, Spinner, Alert, Collapse } from 'react-bootstrap';
import APICommunicator from 'api/APICommunicator'
import { useAuth } from 'context/AuthContext'
import { toast } from 'react-toastify';

const API = new APICommunicator('asd');

const ForgetPassword = () => {
  var randomBgr = bgrImages[Math.floor(Math.random() * bgrImages.length)];
  var [emailSent, setEmailSent] = useState(false)
  var [emailSentAnimationStopped, setSentAnimationStopped] = useState(true)
  const [successMessage, setSuccessMessage] = useState('')
  const { forgotten_password } = useAuth();

  const forgotPasswordAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: ForgotPasswordAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const emailSentAnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: emailSentAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleResetPasswordError = Response => toast.error(API.handleResponseErrors(Response), {
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false
  })

  const handleResetPasswordResponse = Response => {
    if (!Response || Response.status !== 'success') return handleResetPasswordError(Response)
    setEmailSent(true)
    setSuccessMessage(Response.message)
    setSentAnimationStopped(false)
  }

  const handleSendEmail = username => {
    forgotten_password(username)
      .then(handleResetPasswordResponse)
      .catch(handleResetPasswordError)
  }


  return (
    <AuthSplitLayout
      bgProps={{ image: randomBgr, position: '50% 76%', overlay: true }}
    >
      <Collapse in={!emailSent}>
        <div id="ForgotPassword" className="text-center">
          <div className="mb-4">
            <Lottie options={forgotPasswordAnimationOptions} isClickToPauseDisabled={true} />
          </div>
          <h4 className="mb-0"> Forgot your password?</h4>
          <small>Enter your email and we'll send you a reset link.</small>
          <ForgottenPasswordForm layout="split" handleSubmission={handleSendEmail} />
        </div>
      </Collapse>
      <Collapse in={emailSent}>
        <div id="ForgotPasswordEmailSent" className="text-center">
          <Lottie options={emailSentAnimationOptions} isClickToPauseDisabled={true} isStopped={emailSentAnimationStopped} ariaRole="div" />
          <Alert variant="success">{successMessage}</Alert>
        </div>

      </Collapse>
    </AuthSplitLayout>
  );
};

export default ForgetPassword;
