import React from 'react';
import LoginForm from 'components/authentication/LoginForm';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgrImages from './randomBgr';
import Flex from 'components/common/Flex';
import logo from 'assets/img/atlass/logo_atlass.png';
import { Link, useHistory } from 'react-router-dom';

const Login = () => {
  var randomBgr = bgrImages[Math.floor(Math.random() * bgrImages.length)];
  return (
    <AuthSplitLayout bgProps={{ image: randomBgr, position: '50% 20%' }}>
      <div className=" text-center p-2 mb-4">
        <Link className="text-white light font-sans-serif fw-bolder fs-4 z-index-1 position-relative"
          to="/"
        >
          <img src={logo} style={{ maxHeight: "200px", width: 'auto', height: 'auto', maxWidth: '100%' }} />
        </Link>
      </div>
      <LoginForm layout="split" hasLabel />
      <Flex alignItems="center" justifyContent="between">
        <div className="text-400 fs--2 mt-4 text-center">
          Atlass, LLC © {new Date().getFullYear()} all rights reserved v{process.env.REACT_APP_VERSION}
        </div>
      </Flex>
    </AuthSplitLayout>
  );
};

export default Login;
